import { fetchTaskComments } from "src/services/tasks/operations";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { computeTimestampToString } from "src/shared/utils/formatDate";
import { validateField } from "src/shared/utils/validateField";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { receiveTaskComments } from "src/modules/tasks/slice";
import { handleCreateTaskComment } from "src/modules/tasks/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

const computeTaskActivitySlugs: { [key: string]: string } = {
    DRAFT_TO_SIGNED: "TaskActivitySlug.draft-to-signed-description",
    ADD_SIGNATURE: "TaskActivitySlug.add-signature-description",
    SIGNED_TO_SENT: "TaskActivitySlug.signed-to-sent-description",
    MARK_AS_DONE: "TaskActivitySlug.mark-as-done-description",
    CANCELLED: "TaskActivitySlug.cancelled-description",
    RETRY_FAILED: "TaskActivitySlug.retry-failed-description",
};

const defaultAvatar = require("src/shared/images/logo/default-avatar.png");

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksDetailsDrawerSectionsActivity({ task }: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [error, setError] = useState(false);

    const { id, TaskActivities } = task;

    const authUser = useAppSelector((state) => getAuthUser(state));

    useEffect(() => {
        fetchTaskComments({ taskId: id }).then((res) =>
            dispatch(receiveTaskComments({ taskId: id, comments: res.data }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleChangeComment = (newComment: string) => {
        setComment(newComment);
    };

    useEffect(() => {
        const { isValid } = validateField("text", comment);

        setError(!isValid);
    }, [comment]);

    const handleSubmitComment = () => {
        setLoading(true);
        dispatch(handleCreateTaskComment({ taskId: id, content: comment }))
            .then(() => setComment(""))
            .finally(() => setLoading(false));
    };

    const handleGlobalKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter" && (comment.trim().length === 0 || loading)) {
                handleSubmitComment();
            }
        },
        [handleSubmitComment]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleGlobalKeyPress);

        return () => document.removeEventListener("keydown", handleGlobalKeyPress);
    }, []);

    const renderTaskContentOrActivity = (type: string | null, content: string | null) => {
        if (type === "comment" || type === "COMMENT" || type === null) {
            return content;
        } else {
            return t(computeTaskActivitySlugs[type]);
        }
    };

    return (
        <div className='drawer-content-section task-details-activity'>
            <Accordion
                iconColor='white'
                icon='chat-bubble'
                iconSize='sm'
                preSelectedOpen
                title={t("Tasks.task-activity")}
                color='white'
            >
                <div className='task-details-activity-body'>
                    <div className='input-section'>
                        <Avatar
                            avatar={authUser?.avatar || undefined}
                            firstname={authUser?.firstname}
                            lastname={authUser?.lastname}
                        />
                        <Input
                            className='d-flex w-100'
                            value={comment}
                            placeholder={t("Tasks.add-comment")}
                            onChange={handleChangeComment}
                            variant={error ? "error" : "primary"}
                            underMessage={error ? t<string>("CommonUse.invalid-characters") : undefined}
                        />
                        <Button
                            label={<Icon name='send' />}
                            onClick={handleSubmitComment}
                            disabled={comment.trim().length === 0 || loading}
                        />
                    </div>

                    <div className='comment-section'>
                        {TaskActivities.map(({ User, createdAt, content, id, type }) => (
                            <div key={`activity-${id}`} className='comment-task-container'>
                                <Avatar
                                    avatar={User?.avatar ?? defaultAvatar}
                                    firstname={User?.firstname}
                                    lastname={User?.lastname}
                                />
                                <div className='comment-task-infos'>
                                    <div className='title'>
                                        <Typography
                                            className='fw-bold'
                                            message={`${User?.firstname ?? ""} ${
                                                User?.lastname ?? ""
                                            } - ${computeTimestampToString(createdAt, "DD/MM/YY - HH:mm")}`}
                                        />
                                    </div>
                                    <Typography message={renderTaskContentOrActivity(type, content)} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Accordion>
        </div>
    );
}
