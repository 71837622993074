import { ALLOWED_CHARACTERS_REGEX, DISALLOW_WINDOWS_CHARACTERS, EMAIL_REGEX } from "../const/regex";

export const validationRules: Record<string, { regex: RegExp; errorKey: string }> = {
    email: { regex: EMAIL_REGEX, errorKey: "CommonUse.no-spec-char-email" },
    common: { regex: ALLOWED_CHARACTERS_REGEX, errorKey: "CommonUse.no-spec-char" },
    text: { regex: DISALLOW_WINDOWS_CHARACTERS, errorKey: "CommonUse.invalid-characters" },
};

export const validateField = (name: string, value: string): { isValid: boolean; errorKey?: string } => {
    const rule = validationRules[name];

    if (!rule) {
        return { isValid: true };
    }

    const isValid = rule.regex.test(value);
    return { isValid, errorKey: isValid ? undefined : rule.errorKey };
};
