import { AdminWalletModel } from "src/shared/models/Wallet";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

type WalletInformationDrawerDetailsProps = {
    wallet: AdminWalletModel;
    userLength: number | undefined;
};

export const WalletInformationDrawerDetails = ({ wallet, userLength }: WalletInformationDrawerDetailsProps) => {
    const { t } = useTranslation();

    return (
        <Accordion title={t("TopUp.account-details")} size='sm' color='white' preSelectedOpen noHeaderPadding>
            <div className='wallet-informations-drawer-details-container'>
                <div className='item'>
                    <Typography size='sm' message={t("CommonUse.name")} />
                    <Typography size='sm' message={wallet.label} />
                </div>
                <div className='item'>
                    <Typography size='sm' message={t("CommonUse.reference")} />
                    <Typography size='sm' message={wallet.reference} />
                </div>
                <div className='item'>
                    <Typography size='sm' message={t("CommonUse.balance")} />
                    <Typography size='sm' message={formatMoneyToString({ amount: wallet.balance ?? 0 })} />
                </div>
                <div className='item'>
                    <Typography size='sm' message={t("Wallets.number-users")} />
                    <Typography size='sm' message={userLength} />
                </div>
                <div className='item'>
                    <Typography size='sm' message={t("Wallets.ordered-signs")} />
                    {wallet.isOrderedSignatures ? (
                        <Tag
                            size='sm'
                            type='accent'
                            color='success'
                            label={t<string>("Expense-policy.active")}
                            icon='check-circle'
                        />
                    ) : (
                        <Tag
                            size='sm'
                            type='accent'
                            color='neutral'
                            label={t<string>("Expense-policy.disabled")}
                            icon='forbidden'
                        />
                    )}
                </div>
                <div className='item'>
                    <Typography size='sm' message={t("Wallets.required-amount-sign")} />
                    <Typography size='sm' message={t("Wallets.required-signer", { number: wallet.nbSignatures })} />
                </div>
                {/* <div className='item'>
                    <Typography size='sm' message={t("Wallets.pay-only-beneficiaries")} />
                    {wallet.isOrderedSignatures ? (
                        <Tag
                            size='sm'
                            type='accent'
                            color='success'
                            label={t<string>("Expense-policy.active")}
                            icon='check-circle'
                        />
                    ) : (
                        <Tag
                            size='sm'
                            type='accent'
                            color='neutral'
                            label={t<string>("Expense-policy.disabled")}
                            icon='forbidden'
                        />
                    )}
                </div> */}
            </div>
        </Accordion>
    );
};
