import Intercom, { boot, hide, show, shutdown, update } from "@intercom/messenger-js-sdk";
import { UserArgs } from "@intercom/messenger-js-sdk/dist/types";
import { INTERCOM_APP_ID, REACT_APP_ENV } from "src/services/utils/const";

import { useEffect, useState } from "react";

import { getAuthUser, getAuthUserPreferenceSelectedCompanyId } from "src/modules/auth/selectors";
import { getAuthCompanies } from "src/modules/authCompanies/selectors";
import { useAppSelector } from "src/store";

export function useIntercom() {
    const [initialized, setInitialized] = useState(false);

    const envCheck = REACT_APP_ENV === "live";

    const authUser = useAppSelector(getAuthUser);
    const selectedCompanyId = useAppSelector(getAuthUserPreferenceSelectedCompanyId);
    const companies = useAppSelector((state) => getAuthCompanies(state));
    const selectedCompany = companies.find((company) => company.id === selectedCompanyId);

    useEffect(() => {
        if (envCheck && !initialized) {
            Intercom({
                app_id: INTERCOM_APP_ID,
                region: "eu",
                user_id: undefined,
                companies: undefined,
            });
            setInitialized(true);
        }
    }, [envCheck, initialized]);

    useEffect(() => {
        if (initialized) {
            const userData: UserArgs = {};
            if (authUser) {
                userData.user_id = authUser.id.toString();
            }

            if (selectedCompany) {
                userData.company = { id: selectedCompany.coreId, name: selectedCompany.name };
            }

            if (Object.keys(userData).length > 0) {
                updateIntercom(userData);
            }
        }
    }, [authUser, selectedCompany, initialized]);
}

export function updateIntercom(updatedData: UserArgs) {
    hide();
    update({
        ...updatedData,
    });
}

export function shutdownIntercom() {
    shutdown();
    boot({
        app_id: INTERCOM_APP_ID,
        region: "eu",
        user_id: undefined,
        companies: undefined,
    });
}

export function openIntercom() {
    show();
}
