import moment from "moment";
import AdaptedToYourNeedsImage from "src/shared/images/loan/adapted-to-your-needs.svg";
import EligibleImage from "src/shared/images/loan/eligible.svg";
import GrowthImage from "src/shared/images/loan/growth.svg";
import FlexibilityImage from "src/shared/images/loan/payback-flex.svg";
import ResponseTimeImage from "src/shared/images/loan/response-time.svg";
import UneligibleImage from "src/shared/images/loan/uneligible.svg";
import { CompanyRole, countryCodeCheck } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";
import { RangeSlider } from "src/shared/components/RangeSlider/RangeSlider";

import "./styles.scss";

const documentEligibleToOpen: { [country: string]: string } = {
    SEN: "https://cdn.julaya.co/marketing/BROCHURE+PRE%CC%82T+AGILE_SEN_AVRIL+2024.pdf",
    CIV: "https://cdn.julaya.co/marketing/BROCHURE+PRE%CC%82T+AGILE_CIV_AVRIL+2024.pdf",
};

const ranges = [
    { limit: 100000, jump: 100 },
    { limit: 1000000, jump: 1000 },
    { limit: 10000000, jump: 10000 },
    { limit: 100000000, jump: 100000 },
    { limit: Infinity, jump: 1000000 },
];

const calculateJumpValue = (value: number) => {
    const range = ranges.find((range) => value <= range.limit);
    return range ? range.jump : 100;
};

const advantagesItems = [
    {
        image: ResponseTimeImage,
        title: "Loans.quick-response",
        description: "Loans.we-will-contact-you",
    },
    {
        image: AdaptedToYourNeedsImage,
        title: "Loans.fits-your-needs",
        description: "Loans.fits-your-needs-description",
    },
    {
        image: FlexibilityImage,
        title: "Loans.flexible-repayment",
        description: "Loans.flexible-repayment-description",
    },
];

const eligibilityItems = [
    {
        image: EligibleImage,
        title: "Loans.you-become-eligible-for-credit-if",
        steps: [
            "Loans.the-flows-are-fcfa-and-more",
            "Loans.transaction-activity-is-regular",
            "Loans.the-age-of-the-account-is-sufficient",
        ],
    },
    {
        image: GrowthImage,
        title: "Loans.get-even-more-financing",
        steps: ["Loans.anticipate-your-payments", "Loans.make-more-transactions"],
    },
];

type LoanSimulatorProps = {
    isEligible: boolean;
    maxRange: number;
    interest: number;
    date?: Date | null;
};

export const LoanSimulator = ({ isEligible, maxRange, interest, date }: LoanSimulatorProps) => {
    const countryCode = useAppSelector((state) => getAuthUser(state)?.countryCode ?? "00225");
    const authUser = useAppSelector((state) => getAuthUser(state));
    const company = useAuthUserSelectedCompany();

    const jumpValue = calculateJumpValue(maxRange);

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN_ONLY] });
    const country = countryCodeCheck[countryCode];

    const [sliderValue, setSliderValue] = useState(maxRange * 0.9);
    const { t } = useTranslation();

    const supportEmail = useMemo(() => {
        switch (country) {
            case "CIV":
                return "support.ci@julaya.co";
            case "SEN":
                return "support.sn@julaya.co";
            case "BEN":
                return "support.bj@julaya.co";
            default:
                return "support.ci@julaya.co";
        }
    }, [country]);

    const handleSetSliderValue = (e: number) => {
        setSliderValue(e);
    };

    const handleOpenLoanDocument = () => {
        window.open(
            `https://form.typeform.com/to/m107790O#full_name=${authUser?.firstname}%20${authUser?.lastname}&company_name=${company?.name}&loan_offer_amount=${maxRange}&full_phone=${authUser?.countryCode}${authUser?.phone}&email=${authUser?.email}`,
            "_blank"
        );
    };

    const handleOpenDocumentEligible = () => {
        window.open(documentEligibleToOpen[country ?? "CIV"], "_blank");
    };

    return (
        <div className='loan-simulator-container'>
            <div className='loan-simulator-header'>
                <Avatar icon='bills' />
                <Typography message={t("Loans.no-ongoing-credits")} className='loan-simulator-header-title' />
            </div>
            <div className='loan-simulator-content'>
                {isEligible && (
                    <>
                        <div className='eligibility-text'>
                            <Typography message={t("Loans.eligeable")} className='fw-bold' />
                            <div className='text-inline'>
                                <Typography message={t("Loans.description")} />
                                <Typography message={t("Loans.call-our-team")} />
                                <div onClick={handleOpenDocumentEligible} className='text-inline cursor-pointer'>
                                    <Typography message={t("Loans.agile")} className='color-lilas-900 underline' />
                                </div>
                            </div>
                            <div className='text-inline larger-margin'>
                                <Typography message={t("Loans.contact-our-team")} />
                                <a href={"mailto:" + t<string>("Countries.support-ci-mail")}>
                                    <Typography
                                        message={t("Countries.support-ci-mail")}
                                        className='color-lilas-900 underline'
                                    />
                                </a>
                            </div>
                            <div className='text-inline larger-margin'>
                                <Typography message={`${t("CommonUse.updated-at")} : `} />
                                {date && (
                                    <Typography
                                        message={moment(date).format("DD/MM/YYYY - HH:mm")}
                                        className='color-neutral-500'
                                    />
                                )}
                            </div>
                        </div>
                        <div className='loan-simulator-slider-container'>
                            <Typography message={t("Loans.offer")} className='fw-bold' />
                            <div className='text-inline'>
                                <Typography message={t("CommonUse.until")} className='color-neutral-500 mr-1 fw-bold' />
                                <Typography
                                    message={`${formatMoneyToString({ amount: maxRange ? maxRange : 0 })}`}
                                    className='color-neutral-500'
                                />
                            </div>
                            <div className='text-inline color-primary mt-2'>
                                <Typography
                                    message={`${formatMoneyToString({ amount: sliderValue })}`}
                                    variant='h3'
                                    className='mt-0 color-lilas-900'
                                />
                            </div>
                            <div className='loan-range-slider-container'>
                                <RangeSlider
                                    maxRange={maxRange}
                                    jumpValue={jumpValue}
                                    sliderValue={sliderValue}
                                    setSliderValue={(e: number) => handleSetSliderValue(e)}
                                />
                            </div>
                            <div className='text-inline color-neutral-500 mt-2 d-flex align-items-center justify-content-center'>
                                <Typography message={`${t("Loans.to-reimburse")} : `} className='fw-bold' />
                                <Typography
                                    message={`${formatMoneyToString({
                                        amount: Math.round(sliderValue + sliderValue * (interest * 0.01)),
                                    })}`}
                                />
                                <ToolTip
                                    activation
                                    children={<Icon className='color-black ml-2' name='information-circle' />}
                                    content={
                                        <div className='text-left d-flex flex-column w-100'>
                                            <Typography className='fw-bold' message={t("Loans.funding-include")} />
                                            <ul className='pl-4'>
                                                <li>
                                                    <Typography
                                                        message={t("Loans.all-outgoing-flows-during-the-loan-period")}
                                                    />
                                                </li>
                                                <li>
                                                    <Typography message={t("Loans.rate-upon-disbursement")} />
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    position='right'
                                />
                            </div>
                            <div className='d-flex justify-content-center mt-2'>
                                <Button
                                    disabled={isUserUnauthorized}
                                    onClick={handleOpenLoanDocument}
                                    label={t("Loans.borrow-now")}
                                />
                            </div>
                        </div>
                    </>
                )}
                {!isEligible && (
                    <>
                        <div className='uneligible-image' style={{ backgroundImage: `url(${UneligibleImage})` }}></div>
                        <div className='uneligible-text'>
                            <Typography message={t("Loans.ilegeable")} size='md' className='fw-bold' />
                            <div className='text-inline'>
                                <Typography message={t("Loans.description")} />
                                <Typography message={t("Loans.call-our-team")} />
                                <div onClick={handleOpenDocumentEligible} className='text-inline cursor-pointer'>
                                    <Typography message={t("Loans.agile")} className='color-lilas-900 underline' />
                                </div>
                            </div>
                            <div className='text-inline larger-margin'>
                                <Typography message={t("Loans.contact-our-team")} />
                                <a href={"mailto:" + supportEmail}>
                                    <Typography
                                        message={t("Countries.support-ci-mail")}
                                        className='color-lilas-900 underline'
                                    />
                                </a>
                            </div>
                            <div className='text-inline larger-margin'>
                                {date && (
                                    <>
                                        <Typography message={`${t("CommonUse.updated-at")} : `} />
                                        <Typography
                                            message={moment(date).format("DD/MM/YYY - HH:mm")}
                                            className='color-neutral-500'
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className='loan-simulator-accordion mb-3'>
                <Accordion
                    title={t("Loans.how-to-be-eligible-for-credit")}
                    icon='question-mark-circle'
                    iconColor='neutral-100'
                    noHeaderPadding
                >
                    <div className='eligibility-accordion-container'>
                        {eligibilityItems.map((item, index) => {
                            return (
                                <div className='eligibility-item' key={index}>
                                    <div
                                        className='eligibility-item-image'
                                        style={{ backgroundImage: `url(${item.image})` }}
                                    ></div>

                                    <div className='eligibility-item-details'>
                                        <ul>
                                            <li className='list-style-override'>
                                                <Typography message={t(item.title)} className='fw-bold' size='xs' />
                                            </li>
                                            {item.steps.map((step) => {
                                                return (
                                                    <li key={step}>
                                                        <Typography message={t(step)} size='xs' />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Accordion>
            </div>
            <div className='loan-simulator-accordion'>
                <Accordion title={t("Loans.discover-benefits")} icon='bulb' iconColor='neutral-100' noHeaderPadding>
                    <div className='advantages-accordion-container'>
                        {advantagesItems.map((advantage, index) => {
                            return (
                                <div className='advantages-item' key={index}>
                                    <div
                                        className='advantages-item-image'
                                        style={{ backgroundImage: `url(${advantage.image})` }}
                                    ></div>
                                    <div className='advantages-item-text'>
                                        <Typography size='xs' message={t(advantage.title)} className='fw-bold' />
                                        <Typography size='xs' message={t(advantage.description)} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Accordion>
            </div>
        </div>
    );
};
