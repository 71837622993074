import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type TaskToSignBannerProps = {
    counter: number;
    signTasks: () => void;
};

export const TaskToSignBanner = ({ counter, signTasks }: TaskToSignBannerProps) => {
    const { t } = useTranslation();

    return (
        <div className='task-to-sign-banner-container' onClick={signTasks}>
            <div className='left-side'>
                <div className='counter'>
                    <Typography message={counter} size='md' className='fw-bold' />
                    <div className='red-dot' />
                </div>
                <Typography message={t("TaskToSignBanner.tasks-need-your-attention")} className='fw-bold' />
            </div>
            <Button color='primary' onClick={signTasks} label={t("Tasks.sign-my-taks")} />
        </div>
    );
};
