import { WalletInformationDrawerContainer } from "./DetailDrawer/Container";
import { AdminWalletsFilters } from "./Filters";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { requestDeleteAdminWallet } from "src/services/admin/operations";
import { AdminWalletParams } from "src/services/admin/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { AdminWalletModel } from "src/shared/models/Wallet";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAdminWallets } from "src/modules/admin/adminWallets/hooks";
import { setAdminWalletCurrentPage, setAdminWalletPageSize } from "src/modules/admin/adminWallets/slice";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-admin.svg").default;
const emptyArchivedImg = require("src/shared/images/empty/empty-user.svg").default;
const successImage = require("src/shared/images/user/success.png");

export type AdminWalletsStateFilters = {
    status?: string[];
    query?: string | undefined;
    page: number;
};

const defaultFilter: AdminWalletParams = {
    page: 1,
    archived: false,
    pageSize: 20,
    query: "",
};

export const AdminWalletsPanel = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const location = useLocation();
    const open = location.state?.open as number;

    const [params, setParams] = useState(defaultFilter);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState<AdminWalletModel | null>(null);
    const [selectedDestinationWallet, setSelectedDestinationWallet] = useState<string | null>(null);

    const [passwordValidationError, setPasswordValidationError] = useState(null);
    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);

    const { wallets, loading, totalCount, pageSize, currentPage } = useAdminWallets(params);

    const isWalletRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_WALLET);

    useEffect(() => {
        dispatch(setAdminWalletCurrentPage({ currentPage: 1 }));
    }, [params]);

    useEffect(() => {
        if (open) {
            handleSwitchOpenDrawer(open)();
        }
    }, [open]);

    const handleCurrentPage = (newCurrentPage: number) => {
        dispatch(setAdminWalletCurrentPage({ currentPage: newCurrentPage }));
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setAdminWalletPageSize({ pageSize: newPageSize }));
    };

    const navigateToCreate = () => {
        navigate("/admin/wallets/create");
    };

    const onClickCopy = (reference: string) => {
        navigator.clipboard.writeText(reference);
        toast.success(t("Wallets.wallet-ref-copied"));
    };

    const handleSwitchOpenDrawer = (walletId?: number) => () => {
        if (!openDrawer) {
            const wallet = wallets.find((wallet) => wallet.id === walletId) ?? null;
            setSelectedWallet(wallet);
        } else {
            setSelectedWallet(null);
        }

        setOpenDrawer(!openDrawer);
    };

    const handleArchiveWallet = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        setPasswordValidationError(null);
        if (selectedDestinationWallet && selectedWallet) {
            try {
                await requestDeleteAdminWallet(selectedWallet.id, {
                    destinationWalletId: selectedDestinationWallet,
                    otp: twoFactor,
                    password,
                });
                navigate("/admin/wallets");
                return Promise.resolve();
            } catch (error: any) {
                setPasswordValidationError(error.message);
                return Promise.reject(error);
            }
        }
    };

    const handleOpenArchiveWalletPasswordValidation = (destinationWalletId: string) => {
        setSelectedDestinationWallet(destinationWalletId);
        handleTogglePasswordModal();
    };

    const handleTogglePasswordModal = () => {
        setIsOpenPasswordModal(!isOpenPasswordModal);
    };

    const handleArchiveSuccess = () => {
        handleSwitchOpenDrawer()();
        dispatch(fetchAdminWallets({ ...params }));
    };

    const tableRows = wallets.map((wallet) => {
        return [
            {
                content: <Avatar icon='wallet' />,
                type: "image",
            },
            {
                content: <Typography message={wallet.label} className='fw-bold ml-1' />,
                onClick: handleSwitchOpenDrawer(wallet.id),
            },

            {
                content: (
                    <div className='d-flex align-items-center gap-1'>
                        <Typography message={wallet.reference} />
                        <Icon
                            className='cursor-pointer'
                            name='square-2-stack'
                            onClick={(event) => {
                                event.stopPropagation();
                                onClickCopy(wallet.reference);
                            }}
                            size='sm'
                            color='neutral-500'
                        />
                    </div>
                ),
                onClick: handleSwitchOpenDrawer(wallet.id),
            },
            {
                content: (
                    <Tag
                        size='sm'
                        type={"accent"}
                        color={(wallet.balance ?? 0) > 0 ? "success" : "neutral"}
                        label={formatMoneyToString({ amount: wallet.balance ?? 0 })}
                    />
                ),
                onClick: handleSwitchOpenDrawer(wallet.id),
            },
            {
                content: wallet.isOrderedSignatures ? (
                    <Tag
                        size='md'
                        type='accent'
                        color='success'
                        icon='check-circle'
                        label={t<string>("Expense-policy.active")}
                    />
                ) : (
                    <Tag
                        size='md'
                        type='accent'
                        color='neutral'
                        icon='forbidden'
                        label={t<string>("Expense-policy.disabled")}
                    />
                ),
                onClick: handleSwitchOpenDrawer(wallet.id),
            },
            {
                content: <Typography message={wallet.nbSignatures} className='fw-bold' />,
                onClick: handleSwitchOpenDrawer(wallet.id),
            },
            // {
            //     content: wallet.isOrderedSignatures ? (
            //         <Tag
            //             size='md'
            //             type='accent'
            //             color='success'
            //             icon='check-circle'
            //             label={t<string>("Expense-policy.active")}
            //         />
            //     ) : (
            //         <Tag
            //             size='md'
            //             type='accent'
            //             color='neutral'
            //             icon='forbidden'
            //             label={t<string>("Expense-policy.disabled")}
            //         />
            //     ),
            //     type: "text",
            //     onClick: () => handleRedirectToDetails(wallet.id),
            // },
        ];
    });

    return (
        <div className='admin-wallet-table-container'>
            <PageHeader title={t<string>("Wallets.my-wallets")}>
                <div className='filter-container'>
                    <AdminWalletsFilters
                        archiveLabel={t("Wallets.archived-wallets")}
                        filters={params}
                        setFilters={setParams}
                    />
                    <ToolTip
                        position='bottom'
                        content={
                            <div className='tooltip-limited'>
                                <Typography message={t("Subscription.not-accessible-feature")} className='fw-bold' />
                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                            </div>
                        }
                        activation={isWalletRestricted}
                    >
                        <Button
                            disabled={isWalletRestricted}
                            onClick={navigateToCreate}
                            icon='plus-circle'
                            variant='primary'
                            label={t("CommonUse.add", { entity: t("Wallets.a-wallet") })}
                        />
                    </ToolTip>
                </div>
            </PageHeader>
            <SelectableTable
                isLoading={loading}
                pagination={{
                    currentPage,
                    totalCount,
                    pageSize: pageSize,
                    onPageChange: handleCurrentPage,
                    onPageSizeChange: handleSetPageSize,
                }}
                header={[
                    "",
                    t("Wallets.account-name"),
                    t("CommonUse.reference"),
                    t("CommonUse.balance"),
                    t("Wallets.ordered-signs"),
                    t("Wallets.number-signs"),
                    // <div className='beneficiary-only-title'>
                    //     <Typography message={t("Expense-policy.beneficiaries-only")} />
                    //     <ToolTip
                    //         position='bottom'
                    //         activation
                    //         content={<Typography message={t("Wallets.beneficiary-only-tooltip")} />}
                    //     >
                    //         <Icon name='information-circle' size='sm' color='neutral-500' />
                    //     </ToolTip>
                    // </div>,
                ]}
                empty={
                    params !== defaultFilter ? (
                        <Empty
                            image={emptyArchivedImg}
                            title={t("Table.no-result-found")}
                            description={t<string>("Table.no-result-found-description")}
                        />
                    ) : (
                        <Empty
                            image={emptyListImg}
                            title={t("Table.welcome-to-julaya")}
                            description={t<string>("Table.welcome-to-julaya-description")}
                            button={
                                <ToolTip
                                    position='right'
                                    content={
                                        <div className='tooltip-limited'>
                                            <Typography
                                                message={t("Subscription.not-accessible-feature")}
                                                className='fw-bold'
                                            />
                                            <Typography message={t("Subscription.unlock-unlimited-access")} />
                                        </div>
                                    }
                                    activation={isWalletRestricted}
                                >
                                    <Button
                                        disabled={isWalletRestricted}
                                        onClick={navigateToCreate}
                                        icon='plus-circle'
                                        label={t("CommonUse.add", { entity: t("Wallets.a-wallet") })}
                                    />
                                </ToolTip>
                            }
                        />
                    )
                }
                rows={tableRows as SelectableTableRow[][]}
            />
            <WalletInformationDrawerContainer
                open={openDrawer}
                handleCloseDrawer={handleSwitchOpenDrawer(undefined)}
                selectedWallet={selectedWallet}
                handleArchiveWallet={handleOpenArchiveWalletPasswordValidation}
            />
            <PasswordValidationModal
                header={{
                    title: t("CommonUse.archive").toString(),
                    icon: "archive",
                }}
                successContent={{
                    image: successImage,
                    text: t("CommonUse.request-taken-into-consideration").toString(),
                }}
                open={isOpenPasswordModal}
                setOpen={handleTogglePasswordModal}
                handleOnSubmit={handleArchiveWallet}
                onSuccess={handleArchiveSuccess}
                error={passwordValidationError}
            />
        </div>
    );
};
