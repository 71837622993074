import { useEffect, useState } from "react";

import { t } from "i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Select } from "src/shared/atoms/Select/Select";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export type TableColumns = {
    id: string;
    label: string;
    isVisible: boolean;
    isEditable: boolean;
};

type TableColumnHiderProps = {
    initialColumns: TableColumns[];
    setColumns: (columns: TableColumns[]) => void;
};
export const TableColumnHider = ({ initialColumns, setColumns }: TableColumnHiderProps) => {
    const [savedColumns, setSavedColumns] = useState(initialColumns);
    const [temporaryColumns, setTemporaryColumns] = useState(initialColumns);
    const [closeSelect, setCloseSelect] = useState(false);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const handleValidate = () => {
        setColumns(temporaryColumns);
        setSavedColumns(temporaryColumns);
        setCloseSelect(true);
    };

    const handleCancel = () => {
        setTemporaryColumns(savedColumns);
        setCloseSelect(true);
    };

    const handleShowItem = (id: string) => () => {
        setTemporaryColumns((prevColumns) =>
            prevColumns.map((col) => (col.id === id ? { ...col, isVisible: true } : col))
        );
    };

    const handleHideItem = (id: string) => () => {
        setTemporaryColumns((prevColumns) =>
            prevColumns.map((col) => (col.id === id ? { ...col, isVisible: false } : col))
        );
    };

    const toggleAllColumnsVisibility = (isVisible: boolean) => {
        setTemporaryColumns((prevColumns) => prevColumns.map((col) => (col.isEditable ? { ...col, isVisible } : col)));
    };

    const handleHideAllItems = () => toggleAllColumnsVisibility(false);
    const handleShowAllItems = () => toggleAllColumnsVisibility(true);

    return (
        <div className='table-column-item-container'>
            <Select
                color='white'
                fitContent
                forceClose={closeSelect}
                content={{
                    header: {
                        component: <Icon data-testid='select-header' name='eye' />,
                    },
                    dropDownContent: {
                        header: {
                            title: (
                                <div className='wallet-header-select-title'>
                                    <Typography message={t("Table.column-visible")} />
                                </div>
                            ),
                            label: (
                                <div className='button'>
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleHideAllItems}
                                        size='sm'
                                        label={t("Table.mask-off")}
                                        disabled={temporaryColumns.every((col) => !col.isVisible)}
                                        testId='hide-all-btn'
                                    />
                                </div>
                            ),
                        },
                        body: (
                            <div className='column-hider-container'>
                                {temporaryColumns
                                    .filter((col) => col.isVisible)
                                    .map((col) => (
                                        <div
                                            className='column-hider-item'
                                            key={col.id}
                                            data-testid={`visible-col-${col.id}`}
                                        >
                                            <Typography message={col.label} />
                                            {col.isEditable ? (
                                                <Button
                                                    variant='ghost'
                                                    color='lilas'
                                                    icon='eye-filled'
                                                    size='sm'
                                                    onClick={handleHideItem(col.id)}
                                                    testId={`hide-btn-${col.id}`}
                                                />
                                            ) : (
                                                <Tag
                                                    label={t<string>("Table.by-default")}
                                                    color='neutral'
                                                    type='accent'
                                                    size='sm'
                                                />
                                            )}
                                        </div>
                                    ))}

                                {temporaryColumns.filter((col) => !col.isVisible).length > 0 && (
                                    <>
                                        <div className='divider' />
                                        <div className='column-hider-title'>
                                            <Typography message={t("Table.hidded-columns")} className='masked-title' />
                                            <Button
                                                variant='ghost'
                                                color='lilas'
                                                onClick={handleShowAllItems}
                                                size='sm'
                                                label={t("Billing.show-everything")}
                                                testId='show-all-btn'
                                            />
                                        </div>
                                        {temporaryColumns
                                            .filter((col) => !col.isVisible)
                                            .map((col) => (
                                                <div
                                                    className='column-hider-item'
                                                    key={col.id}
                                                    data-testid={`hidden-col-${col.id}`}
                                                >
                                                    <Typography message={col.label} />
                                                    <Button
                                                        variant='ghost'
                                                        color='lilas'
                                                        icon='eye-off-filled'
                                                        size='sm'
                                                        onClick={handleShowItem(col.id)}
                                                        testId={`show-btn-${col.id}`}
                                                    />
                                                </div>
                                            ))}
                                    </>
                                )}
                            </div>
                        ),
                        footer: (
                            <>
                                <Button
                                    className='background-neutral-200 footer-btn'
                                    variant='tertiary'
                                    onClick={handleCancel}
                                    label={t("CommonUse.cancel")}
                                    testId='cancel-btn'
                                />
                                <Button
                                    className='footer-btn'
                                    variant='primary'
                                    onClick={handleValidate}
                                    label={t("CommonUse.validate")}
                                    testId='validate-btn'
                                />
                            </>
                        ),
                    },
                }}
            />
        </div>
    );
};
