import classNames from "classnames";
import { BillingBillerUpdatePayload } from "src/services/billing/enterprise/type";

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getBillingEnterprise } from "src/modules/billing/enterprise/selectors";
import { getWalletByReference, getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

type Props = {
    edit: "details" | "invoiceReceipt" | null;
    setEdit: Dispatch<SetStateAction<"details" | "invoiceReceipt" | null>>;
    handleSetPayload: (props: BillingBillerUpdatePayload) => () => void;
};

export function BillingGetPaidDrawerSectionInvoiceReceipt({ edit, setEdit, handleSetPayload }: Readonly<Props>) {
    const { t } = useTranslation();

    const [selectedWallet, setSelectedWallet] = useState<null | SelectOption>(null);

    const enterpriseData = useAppSelector((state) => getBillingEnterprise(state));
    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));
    const wallet = useAppSelector((state) =>
        getWalletByReference(state, { walletReference: enterpriseData?.billsWalletReference ?? "" })
    );

    const handleSetEdit = (value: Props["edit"]) => () => {
        setEdit(value);
    };

    const handleChangeWallet = (value: SelectOption) => {
        setSelectedWallet(value);
    };

    useEffect(() => {
        if (wallet) {
            setSelectedWallet({ id: String(wallet.id), label: wallet.label ?? "" });
        }
    }, [wallet]);

    const editDisabled = edit !== null && edit !== "invoiceReceipt";

    const payload = useMemo(
        () => ({
            label: selectedWallet?.label ?? "",
            walletReference: parseInt(selectedWallet?.id ?? ""),
        }),
        [selectedWallet]
    );

    return (
        <div
            className={classNames("drawer-content-section get-paid-drawer-section", {
                disabled: editDisabled,
            })}
        >
            <div className='header'>
                <Typography className='fw-bold' message={t("Bills.invoice-receipt-title")} />
                {edit === "invoiceReceipt" ? (
                    <Button
                        disabled={editDisabled}
                        onClick={handleSetEdit(null)}
                        label={t("CommonUse.being-modified")}
                        variant='ghost'
                        color='warning'
                    />
                ) : (
                    <Button
                        disabled={editDisabled}
                        onClick={handleSetEdit("invoiceReceipt")}
                        label={t("CommonUse.modify")}
                        variant='ghost'
                        color='lilas'
                    />
                )}
            </div>
            <div className='drawer-divider' />
            {edit === "invoiceReceipt" ? (
                <>
                    <SimpleSelect
                        placeholder={t<string>("CommonUse.select-account")}
                        color='grey'
                        useRadio
                        headerClassname='drawer-input-header'
                        label={t<string>("Bills.receipt-bill-payment-wallet")}
                        options={walletOptions}
                        selected={selectedWallet}
                        onSelectionChange={handleChangeWallet}
                    />
                    <div className='drawer-divider' />
                    <div className='d-flex justify-content-end'>
                        <Button
                            className='mr-2'
                            variant='tertiary'
                            label={t("CommonUse.cancel")}
                            onClick={handleSetEdit(null)}
                        />
                        <Button label={t("CommonUse.modify")} onClick={handleSetPayload(payload)} />
                    </div>
                </>
            ) : (
                <div className='label-or-description'>
                    <Typography className='element color-neutral-500 fw-bold' message={t("Bills.receipt-account")} />
                    <Typography
                        className='element fw-bold'
                        message={`${wallet?.label ? `${wallet.label} - ` : ""}${
                            enterpriseData?.billsWalletReference ?? ""
                        }`}
                    />
                </div>
            )}
        </div>
    );
}
