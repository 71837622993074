import { EditTaskInputCell } from "../InputCell";
import { createTaskItems } from "src/services/taskItems/operations";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useContext } from "react";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { fetchTaskItems } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    taskId?: number;
    handleCancelCreateTaksItem: () => void;
};

export function TasksItemEditFieldCreateAmount({ taskId, handleCancelCreateTaksItem }: Props) {
    const dispatch = useAppDispatch();

    const { taskItem, loading, setLoading, setTaskItem } = useContext(CreateTaskItemContext);

    const { externalReference, serviceSlug, amount, externalFullname, typeSlug } = taskItem ?? {};

    const handleUpdateAmount = async (newAmount: string) => {
        if (taskId && taskItem && externalReference && serviceSlug && parseInt(newAmount)) {
            setLoading(true);
            setTaskItem({ ...taskItem, amount: parseInt(newAmount ?? "") });
            await createTaskItems({
                taskId,
                tasksItems: [
                    {
                        externalReference,
                        externalFullname,
                        serviceSlug,
                        amount: newAmount,
                        typeSlug,
                    },
                ],
            }).then(() => dispatch(fetchTaskToEdit({ taskId })));
            handleCancelCreateTaksItem();
            setLoading(false);
            dispatch(
                fetchTaskItems({
                    taskId,
                })
            );
        }
    };

    return (
        <EditTaskInputCell
            editOpen={!!externalReference && !!externalFullname && !amount}
            initialValue={String(amount ?? "")}
            disabled={loading}
            onValidate={handleUpdateAmount}
            type='number'
            testId='edit-task-row-amount-input'
        >
            <Typography
                message={formatMoneyToString({ amount: amount ?? 0 })}
                className='edit-task-table-cell-content'
            />
        </EditTaskInputCell>
    );
}
