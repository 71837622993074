import { WalletModel } from "src/shared/models/Wallet";

import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { TextArea } from "src/shared/atoms/Inputs/TextArea";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { AmountInput } from "src/shared/components/AmountInput/AmountInput";

type Props = {
    debitAccount: WalletModel;
    comment: string;
    amount: number;
    disabled: boolean;
    setComment: Dispatch<SetStateAction<string>>;
    setAmount: (value: number) => void;
    error: string;
};

export function BillingPayBillPaymentAmount({
    debitAccount,
    amount,
    comment,
    disabled,
    setAmount,
    setComment,
    error,
}: Readonly<Props>) {
    const { t } = useTranslation();

    const handleSetComment = (value: string) => {
        setComment(value);
    };

    return (
        <div className='transfert-details-container inputs-container'>
            <AmountInput
                label={t("TransfertDetails.montant-a-transerer")}
                disabled={disabled}
                name='amount'
                placeholder='0 FCFA'
                value={amount}
                handleChange={setAmount}
                variant={(debitAccount?.balance ?? 0) < amount ? "error" : undefined}
                underMessage={
                    (debitAccount?.balance ?? 0) < amount
                        ? "TransfertDetails.le-montant-est-superieur-au-solde-de-votre-compte"
                        : undefined
                }
                autoFocus
            />
            <TextArea
                value={comment}
                onChange={handleSetComment}
                variant='neutral'
                placeholder={t<string>("Bills.add-a-comment")}
            />
            {error && (
                <div className='d-flex align-items-center'>
                    <Icon name='x-circle-filled' size='sm' color='error' />
                    <Typography className='ml-2 color-error' message={t(error)} />
                </div>
            )}
        </div>
    );
}
