import { WalletInformationDrawerArchive } from "./Archive/Archive";
import { WalletInformationDrawerDetails } from "./Details/Details";
import { WalletInformationDrawerSignataires } from "./Signataires/Signataires";
import { useNavigate } from "react-router-dom";
import { requestMiddlewareAdminWalletById } from "src/services/admin/operations";
import { AdminWalletModel } from "src/shared/models/Wallet";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

type WalletInformationDrawerContainerProps = {
    open: boolean;
    handleCloseDrawer: () => void;
    selectedWallet: AdminWalletModel | null;
    handleArchiveWallet: (destinationWalletId: string) => void;
};

export const WalletInformationDrawerContainer = ({
    open,
    handleCloseDrawer,
    selectedWallet,
    handleArchiveWallet,
}: Readonly<WalletInformationDrawerContainerProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [wallet, setWallet] = useState<AdminWalletModel | null>(null);

    useEffect(() => {
        if (selectedWallet) {
            requestMiddlewareAdminWalletById(parseInt(selectedWallet.id.toString()))
                .then((response) => {
                    setWallet(response);
                })
                .catch(() => {
                    setWallet(null);
                })
                .finally(() => {});
        }
    }, [selectedWallet]);

    const handleEditWallet = () => {
        if (wallet) {
            navigate(`/admin/wallets/${wallet.id}/edit`);
        }
    };

    const closeDrawer = () => {
        setWallet(null);
        handleCloseDrawer();
    };

    return (
        <Drawer
            isOpen={open}
            onClose={closeDrawer}
            header={{ title: t("TopUp.account-details"), subtitle: selectedWallet?.label, image: "wallet" }}
            body={
                <div className='wallet-information-drawer-container'>
                    {selectedWallet && (
                        <WalletInformationDrawerDetails wallet={selectedWallet} userLength={wallet?.users.length} />
                    )}
                    {wallet && wallet.users.length > 0 && <WalletInformationDrawerSignataires wallet={wallet} />}
                    {selectedWallet && (
                        <WalletInformationDrawerArchive
                            wallet={selectedWallet}
                            handleArchiveWallet={handleArchiveWallet}
                        />
                    )}
                </div>
            }
            footer={{
                primaryButton: {
                    label: t("Wallets.edit"),
                    onClick: handleEditWallet,
                },
            }}
        />
    );
};
