import { TaskItemModelToCreate } from "src/services/taskItems/types";

import { Dispatch, SetStateAction, createContext } from "react";

export type CreateTaskItemContextProps = {
    taskItem: TaskItemModelToCreate | undefined;
    setTaskItem: Dispatch<SetStateAction<TaskItemModelToCreate | undefined>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
};

export const CreateTaskItemContext = createContext<CreateTaskItemContextProps>({} as CreateTaskItemContextProps);
