import { BeneficiaryDrawer } from "../../Beneficiary/Drawer/Container";
import { EditTaskSummaryPanel } from "./Summary/Panel";
import { TasksEditFooterDialog } from "./footer/Dialog";
import { EditTaskFooterSummary } from "./footer/Summary";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { defaultTaskItem } from "src/services/taskItems/const";
import { TaskItemModelToCreate } from "src/services/taskItems/types";
import { WalletBeneficiary } from "src/services/wallets/types";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";
import { parseCreateTaskFile } from "src/utils/xlsx/parseTask";
import * as XLSX from "xlsx";

import { ChangeEvent, useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { useComputeRowsForEditTable } from "./hooks";
import { useRouterTaskToEdit } from "src/modules/tasks/hooks";
import { getTask, getTaskToEditLoading } from "src/modules/tasks/selectors";
import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { getTaskItemsFileUploading, getTasksItemsIsLoading } from "src/modules/tasksItems/selectors";
import {
    deleteTaskItem,
    handleCreateTaskitems,
    handleDuplicateTaskitems,
    handleUpdateTaskItem,
} from "src/modules/tasksItems/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Select } from "src/shared/atoms/Select/Select";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { SelectableTable } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyImage = require("src/shared/images/icons/no-tasks.png");

const header = (t: TFunction<string>) => [
    "",
    t("CommonUse.identifier"),
    t("CommonUse.name"),
    t("CommonUse.amount"),
    t("Tasks.julaya-fees"),
    t("CommonUse.comment"),
    "",
];

type LocationState = {
    duplicateTaskId?: number;
};

export function EditTaskPanel() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const state = useLocation().state as LocationState;

    const [duplicate, setDuplicate] = useState(false);
    const [screen, setScreen] = useState<"edit" | "signature">("edit");
    const [open, setOpen] = useState(false);
    const [taskItemToEdit, setTaskItemToEdit] = useState<TaskItemTmpModel>();
    const [createdItem, setCreatedItem] = useState<TaskItemModelToCreate>();
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [selectedRows, setSelectedRows] = useState<number[]>();

    const task = useRouterTaskToEdit();

    const taskId = task?.id;

    const taskToDuplicate = useAppSelector((reduxState) =>
        state?.duplicateTaskId ? getTask(reduxState, { taskId: state.duplicateTaskId }) : null
    );
    const taskToEditLoading = useAppSelector((state) => getTaskToEditLoading(state));
    const taskItemsLoading = useAppSelector((state) => getTasksItemsIsLoading(state));
    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const isNbTransactionsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_15_TRANSACTION);

    const contextState = useMemo(() => {
        return {
            taskItem: createdItem,
            setTaskItem: setCreatedItem,
            loading: loadingCreate,
            setLoading: setLoadingCreate,
        };
    }, [createdItem, loadingCreate]);

    const handleOpenDrawer = (taskItem: TaskItemTmpModel) => () => {
        setOpen(true);
        setTaskItemToEdit(taskItem);
    };

    const handleCancelCreateTaskItem = () => {
        setCreatedItem(undefined);
    };

    const { rows, taskItems } = useComputeRowsForEditTable({
        handleOpenDrawer,
        handleCancelCreateTaskItem,
        createdItem,
        loadingCreate,
        taskId,
    });

    useEffect(() => {
        if (!duplicate && taskToDuplicate && task && !task?.metadata?.sum) {
            setDuplicate(true);
            dispatch(handleDuplicateTaskitems({ taskId: task.id, taskToDuplicate }));
        }
    }, [state]);

    useEffect(() => {
        if (!taskToEditLoading && !task) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskToEditLoading, task]);

    const handleCloseDrawer = () => {
        setOpen(false);
        setTaskItemToEdit(undefined);
        if (!createdItem?.serviceSlug) {
            setCreatedItem(undefined);
        }
    };

    const handleSelectBeneficiary = ({
        serviceSelected: { externalFullname, externalReference, serviceSlug },
        typeSlug,
    }: WalletBeneficiary) => {
        if (
            taskId &&
            taskItemToEdit &&
            (externalFullname !== taskItemToEdit?.externalFullname ||
                externalReference !== taskItemToEdit?.externalReference ||
                serviceSlug !== taskItemToEdit.serviceSlug)
        ) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItemToEdit,
                    update: {
                        externalReference,
                        externalFullname: removeExtraSpaces(externalFullname),
                        serviceSlug,
                        typeSlug,
                    },
                })
            ).then(() => dispatch(fetchTaskToEdit({ taskId })));
            handleCloseDrawer();
        }
    };

    const handleSelectBeneficiaryCreateTaskitem = ({
        serviceSelected: { externalFullname, externalReference, serviceSlug },
        typeSlug,
    }: WalletBeneficiary) => {
        setCreatedItem({
            ...defaultTaskItem,
            externalFullname: removeExtraSpaces(externalFullname),
            externalReference,
            serviceSlug,
            typeSlug,
        });
        setOpen(false);
    };

    const handleOpenDrawerCreateTaskItem = () => {
        if (disabled) {
            return;
        }
        setTaskItemToEdit(undefined);
        if (!createdItem) {
            setCreatedItem(defaultTaskItem);
        }
        setOpen(true);
    };

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                let data = e?.target?.result;
                if (data && taskId) {
                    if (isNbTransactionsRestricted) {
                        const readedData = XLSX.read(data, { type: "binary" });

                        const firstSheet = readedData.Sheets[readedData.SheetNames[0]];
                        const dataParseFirstSheet = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
                        const nonEmptyRows = dataParseFirstSheet.filter((row) =>
                            row.some((cell) => cell !== null && cell !== undefined && cell !== "")
                        );

                        if (nonEmptyRows.length - 1 + Object.keys(taskItems).length > 15) {
                            toast.error(t("BulkTransferPage.free-plan-warning-list"));
                            return;
                        }
                    }

                    try {
                        const fileData = parseCreateTaskFile(t, data);
                        if (typeof fileData === "string") {
                            alert(fileData);
                        } else if (fileData) {
                            dispatch(handleCreateTaskitems({ taskId, taskItems: fileData }))
                                .unwrap()
                                .then(() => dispatch(fetchTaskToEdit({ taskId })))
                                .catch((error) => {
                                    alert(t(error.message));
                                });
                        } else {
                            alert(t("Tasks.file-is-not-standart-choose-another"));
                        }
                    } catch {
                        alert(t("Tasks.file-is-not-standart-choose-another"));
                    }
                }
            };
            reader.readAsBinaryString(selectedFile);
            event.target.value = "";
        }
    };

    const handleSwitchToSignature = () => {
        setScreen("signature");
    };

    const handleSwitchToEdit = () => {
        setScreen("edit");
    };

    const handleSelectRows = (rows: number[]) => {
        setSelectedRows(rows);
    };

    const handleDeleteTaskItems = () => {
        const tasksFiltered = selectedRows?.reduce<string[]>((res, selectedRow) => {
            const row = rows[selectedRow];
            if (row?.[0]?.id === "createItem") {
                handleCancelCreateTaskItem();
            } else if (row?.[0]?.id) {
                res.push(row[0].id);
            }
            return res;
        }, []);
        if (taskId && tasksFiltered?.length) {
            dispatch(deleteTaskItem({ taskId, taskItemIds: tasksFiltered }));
        }
    };

    const freePlanLimit = isNbTransactionsRestricted && Object.keys(taskItems).length >= 15;
    const disabled = !!createdItem || freePlanLimit;
    const fileUploading = Object.keys(taskItemsFileUploading ?? {}).length > 0;

    return (
        <div className='edit-task-container'>
            {screen === "edit" ? (
                <CreateTaskItemContext.Provider value={contextState}>
                    {rows.length > 0 ? (
                        <SelectableTable
                            setSelectedRows={handleSelectRows}
                            selectedBar={{
                                itemName: t<string>("Transactions.operation"),
                                buttonActions: {
                                    fitContent: true,
                                    content: {
                                        header: { title: t<string>("Transactions.multiple-actions"), icon: "squares" },
                                        dropDownContent: {
                                            body: (
                                                <Button
                                                    label={t("Tasks.delete-transactions")}
                                                    icon='thrash'
                                                    variant='ghost'
                                                    onClick={handleDeleteTaskItems}
                                                    disabled={!selectedRows}
                                                />
                                            ),
                                        },
                                    },
                                },
                            }}
                            selectable={true}
                            isLoading={taskItemsLoading && !taskItems}
                            header={header(t)}
                            rows={rows}
                        />
                    ) : (
                        <div className='mt-4 mb-4'>
                            <Empty
                                image={emptyImage}
                                title={t("Tasks.no-transaction-added")}
                                description={t<string>("Tasks.add-transactions-or-upload-file")}
                            />
                        </div>
                    )}
                    <div className='d-flex w-100 justify-content-center mb-5'>
                        <ToolTip
                            position='top'
                            activation={freePlanLimit}
                            content={
                                <div className='tooltip-limited'>
                                    <Typography message={t("Subscription.operations-limit")} className='fw-bold' />
                                    <Typography message={t("Subscription.unlock-unlimited-access")} />
                                </div>
                            }
                        >
                            <div className={`d-flex edit-task-select-button ${disabled ? "disabled" : ""}`}>
                                <Button
                                    disabled={disabled}
                                    label={t("EditTaskPanel.ajouter-une-operation")}
                                    onClick={handleOpenDrawerCreateTaskItem}
                                    testId='create-task-add-operation-button'
                                />
                                <div className='task-button-vertical-divider' />
                                <Select
                                    forceClose={fileUploading || !!createdItem || open}
                                    fitContent
                                    contentClassName='task-button-select-content'
                                    type='warning'
                                    content={{
                                        header: { disabled: false, className: "task-button-select-button" },
                                        dropDownContent: {
                                            body: (
                                                <div className='edit-task-select-container'>
                                                    <div className='px-2 pt-1 pb-3'>
                                                        <div
                                                            className={`select-item cursor-pointer ${
                                                                disabled ? "disabled" : ""
                                                            }`}
                                                            onClick={handleOpenDrawerCreateTaskItem}
                                                        >
                                                            <Icon name='plus' size='sm' />
                                                            <Typography
                                                                className='fw-bold ml-2'
                                                                message={t("EditTaskPanel.ajouter-une-operation")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='px-2 pb-3'>
                                                        <label className='cursor-pointer' htmlFor='files'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div
                                                                    className={`select-item ${
                                                                        disabled || fileUploading ? "disabled" : ""
                                                                    }`}
                                                                >
                                                                    <Icon name='document' size='sm' />
                                                                    <Typography
                                                                        className='fw-bold ml-2'
                                                                        message={t("EditTaskPanel.utiliser-un-modele")}
                                                                    />
                                                                </div>
                                                                {fileUploading && <Spinner size='xs' />}
                                                            </div>
                                                        </label>
                                                        <input
                                                            type='file'
                                                            disabled={fileUploading || disabled}
                                                            id='files'
                                                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                            onChange={onFileLoaded}
                                                            style={{ display: "none" }}
                                                        />
                                                    </div>
                                                    <div className='px-2 pb-2'>
                                                        <a
                                                            href={`/models/bulk_transfert_modele_julaya.xlsx`}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            className='select-item'
                                                        >
                                                            <Icon name='arrow-down-on-square' size='sm' />
                                                            <Typography
                                                                className='fw-bold ml-2'
                                                                message={t("EditTaskPanel.download-model")}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    }}
                                />
                            </div>
                        </ToolTip>
                    </div>
                </CreateTaskItemContext.Provider>
            ) : (
                <EditTaskSummaryPanel />
            )}
            <div className='edit-task-footer'>
                <TasksEditFooterDialog taskId={taskId} />
                <EditTaskFooterSummary
                    taskCreation={!!createdItem}
                    taskId={taskId}
                    screen={screen}
                    handleSwitchToEdit={handleSwitchToEdit}
                    handleSwitchToSignature={handleSwitchToSignature}
                />
            </div>
            <BeneficiaryDrawer
                isOpen={open}
                walletId={String(task?.walletId)}
                handleSetClose={handleCloseDrawer}
                handleSelectBeneficiary={createdItem ? handleSelectBeneficiaryCreateTaskitem : handleSelectBeneficiary}
            />
        </div>
    );
}
