import { BillingBiller } from "src/services/billing/biller/type";
import { WalletBeneficiary } from "src/services/wallets/types";
import { WalletModel } from "src/shared/models/Wallet";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { BillReferenceFields } from "src/modules/transactions/thunkActions";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type TransfertDetailsProps = {
    debitAccount: WalletModel | null;
    beneficiary?: WalletBeneficiary;
    amount: number;
    biller?: BillingBiller;
    fields: BillReferenceFields;
};

export const BillsTransfertRecap = ({ debitAccount, beneficiary, amount, biller, fields }: TransfertDetailsProps) => {
    const { t } = useTranslation();

    const fee = beneficiary?.serviceSelected.fees ? (beneficiary.serviceSelected.fees / 100) * amount : 0;

    return (
        <div className='bills-details-container'>
            {biller && (
                <>
                    <div className='bills-details-row'>
                        <Typography message={t("TransfertDetails.destinataire")} />
                        <Typography message={biller?.name} />
                    </div>
                    <div className='bills-details-row'>
                        <Typography message={t("TransfertDetails.reference-entreprise")} />
                        <Typography message={biller?.reference} />
                    </div>
                    {fields.invoiceReference && (
                        <div className='bills-details-row'>
                            <Typography message={t("TransfertDetails.reference-facture")} />
                            <Typography message={fields.invoiceReference} />
                        </div>
                    )}
                    {fields.payerReference && (
                        <div className='bills-details-row'>
                            <Typography message={t("TransfertDetails.payer-ref")} />
                            <Typography message={fields.payerReference} />
                        </div>
                    )}
                    <div className='divider' />
                </>
            )}
            <div className='bills-details-row'>
                <Typography message={t("TransfertDetails.compte-emetteur")} />
                <Typography message={debitAccount?.label} />
            </div>
            <div className='bills-details-row'>
                <Typography message={t("TransfertDetails.solde-disponible")} />
                <Typography message={formatMoneyToString({ amount: debitAccount?.balance ?? 0 })} />
            </div>
            <div className='divider' />
            <div className='bills-details-row'>
                <Typography className='fw-bold' message={t("Transactions.service")} />
                <div className='service-row'>
                    {beneficiary?.serviceSelected.serviceSlug && (
                        <TransactionServiceImage
                            classname='service-image-icon'
                            serviceSlug={beneficiary?.serviceSelected.serviceSlug}
                        />
                    )}
                    <Typography className='fw-bold' message={beneficiary?.typeTitle} />
                </div>
            </div>

            <div className='bills-details-row'>
                <Typography message={t("Bills.transaction-fee", { amount: beneficiary?.serviceSelected.fees ?? 0 })} />
                <Typography message={formatMoneyToString({ amount: fee ?? 0 })} />
            </div>
        </div>
    );
};
