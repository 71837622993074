import { CountryOptions } from "./const";
import moment from "moment";
import { UserModel } from "src/shared/models/User";
import { validateField } from "src/shared/utils/validateField";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDebounce } from "src/shared/hooks/useDebounce";

import { Input } from "src/shared/atoms/Inputs/Input";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

const genderOptionsRaw = (t: (key: string) => string): SelectOption[] => [
    { id: t("AdminAccountsFormsProfile.m"), label: t("CommonUse.mr") },
    { id: t("AdminAccountsFormsProfile.f"), label: t("CommonUse.mme") },
];

type ProfileKeys =
    | "gender"
    | "countryOfCitizenship"
    | "firstname"
    | "lastname"
    | "email"
    | "dateOfBirth"
    | "customReference";
type ProfileForm = Pick<UserModel, ProfileKeys>;
export type ProfileParams = ProfileForm;

type AdminAccountsFormsProfileProps = {
    detail?: UserModel | null;
    onChange: (values: ProfileParams) => void;
    handleValidatedProfile?: (validated: boolean) => void;
};

export const AdminAccountsFormsProfile = ({
    detail,
    onChange,
    handleValidatedProfile,
}: AdminAccountsFormsProfileProps) => {
    const { t } = useTranslation();

    const [selectedGenderOption, setSelectedGenderOption] = useState<SelectOption | null>(null);
    const [selectedCountryOption, setSelectedCountryOption] = useState<SelectOption | null>(null);
    const [fields, setFields] = useState<ProfileParams>({
        firstname: "",
        lastname: "",
        email: "",
        dateOfBirth: "",
        customReference: "",
        gender: "",
        countryOfCitizenship: "",
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isEmailTouched, setIsEmailTouched] = useState(false);

    const GenderOptions = useMemo(() => genderOptionsRaw(t), [t]);

    useEffect(() => {
        onChange(fields);
    }, [fields]);

    useEffect(() => {
        const isValidatedForm =
            !!selectedGenderOption &&
            !!selectedCountryOption &&
            !!fields.firstname &&
            !!fields.lastname &&
            !!fields.email &&
            !!fields.dateOfBirth;

        const noSpecChar = Object.keys(errors).every((key) => errors[key] === "");

        if (handleValidatedProfile) {
            handleValidatedProfile(isValidatedForm && noSpecChar);
        }
    }, [selectedGenderOption, selectedCountryOption, fields, errors]);

    useEffect(() => {
        if (detail) {
            setSelectedGenderOption(GenderOptions.find((option) => option.id === detail.gender) || null);
            setSelectedCountryOption(
                CountryOptions.find((option) => option.id === detail.countryOfCitizenship?.toLowerCase()) || null
            );
            setFields({
                firstname: detail.firstname,
                lastname: detail.lastname,
                email: detail.email ?? "",
                dateOfBirth: detail.dateOfBirth ?? "",
                customReference: detail.UserCompanies?.[0]?.customReference ?? "",
                gender: detail.gender,
                countryOfCitizenship: detail.countryOfCitizenship ?? "",
            });
        }
    }, [detail, GenderOptions]);

    const handleChangeField = (name: keyof ProfileParams, type: "text" | "email" | "common") => (value: string) => {
        setFields((prev) => ({ ...prev, [name]: value }));
        if (name !== "email") {
            const { isValid, errorKey } = validateField(type, value);
            setErrors((prev) => (isValid ? { ...prev, [name]: "" } : { ...prev, [name]: t<string>(errorKey!) }));
        }
    };

    const debouncedEmail = useDebounce(fields.email, 700);

    useEffect(() => {
        if (debouncedEmail && !validateField("email", debouncedEmail).isValid) {
            setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
        } else {
            setErrors((prev) => {
                const { email, ...rest } = prev;
                return rest;
            });
        }
    }, [debouncedEmail, t]);

    const handleBlur = (name: string) => () => {
        if (name === "email") {
            setIsEmailTouched(true);

            if (fields.email && !validateField("email", fields.email).isValid) {
                setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
            } else {
                setErrors((prev) => {
                    const { email, ...rest } = prev;
                    return rest;
                });
            }
        }
    };

    const handleChangeGender = (option: SelectOption) => {
        setSelectedGenderOption(option);
        setFields((prev) => ({ ...prev, gender: option.id }));
    };

    const handleChangeCountry = (option: SelectOption) => {
        setSelectedCountryOption(option);
        setFields((prev) => ({ ...prev, countryOfCitizenship: option.id }));
    };

    const handleChangeDateOfBirth = (date: Date | null) => {
        setFields((prev) => ({ ...prev, dateOfBirth: moment(date).format("YYYY-MM-DD") }));
    };

    return (
        <div className='profile-form-container'>
            <div className='profile-form-item'>
                <SimpleSelect
                    label={t<string>("AdminAccountsFormsProfile.civilite")}
                    placeholder={t<string>("AdminAccountsFormsProfile.civilite")}
                    selected={selectedGenderOption}
                    onSelectionChange={handleChangeGender}
                    useRadio
                    options={GenderOptions}
                />
            </div>
            <div className='profile-form-item'>
                <SimpleSelect
                    label={t<string>("AdminAccountsFormsProfile.nationalite")}
                    placeholder={t<string>("CommonUse.country-of-citizenship")}
                    selected={selectedCountryOption}
                    onSelectionChange={handleChangeCountry}
                    useRadio
                    options={CountryOptions}
                />
            </div>
            <div className='profile-form-item'>
                <Input
                    label={t<string>("AdminAccountsFormsProfile.prenom")}
                    name='firstname'
                    value={fields.firstname}
                    placeholder={t<string>("CommonUse.firstnames")}
                    onChange={handleChangeField("firstname", "common")}
                    variant={errors.firstname ? "error" : undefined}
                    underMessage={errors.firstname}
                />
            </div>
            <div className='profile-form-item'>
                <Input
                    label={t<string>("AdminAccountsFormsProfile.nom")}
                    name='lastname'
                    value={fields.lastname}
                    placeholder={t<string>("CommonUse.lastname")}
                    onChange={handleChangeField("lastname", "common")}
                    variant={errors.lastname ? "error" : undefined}
                    underMessage={errors.lastname}
                />
            </div>
            <div className='profile-form-item'>
                <Input
                    label={t<string>("AdminAccountsFormsProfile.adresse-mail")}
                    name='email'
                    value={fields.email}
                    placeholder='email@example.com'
                    onChange={handleChangeField("email", "email")}
                    onBlur={handleBlur("email")}
                    variant={errors.email || (isEmailTouched && !fields.email) ? "error" : "primary"}
                    underMessage={errors.email}
                />
            </div>
            <div className='profile-form-item'>
                <CustomDatePicker
                    label={t("Users.date-of-birth")}
                    single
                    startDate={fields.dateOfBirth ? new Date(fields.dateOfBirth) : null}
                    onChangeStartDate={handleChangeDateOfBirth}
                />
            </div>
            <div className='profile-form-item'>
                <Input
                    label={t<string>("Users.user-reference") + " (" + t<string>("CommonUse.facultative") + ")"}
                    name='customReference'
                    value={fields.customReference}
                    onChange={handleChangeField("customReference", "text")}
                    placeholder={t<string>("Users.personalized-reference")}
                    variant={errors.customReference ? "error" : undefined}
                    underMessage={errors.customReference}
                />
            </div>
        </div>
    );
};
