import { AdminWalletModel } from "src/shared/models/Wallet";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWalletsAsSelectOptions } from "src/modules/admin/adminWallets/selectors";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

type WalletInformationDrawerArchiveProps = {
    wallet: AdminWalletModel;
    handleArchiveWallet: (destinationWalletId: string) => void;
};

export const WalletInformationDrawerArchive = ({
    wallet,
    handleArchiveWallet,
}: WalletInformationDrawerArchiveProps) => {
    const { t } = useTranslation();

    const allWalletOptions = useAppSelector(getAdminWalletsAsSelectOptions);
    const walletOptions = allWalletOptions.filter((option) => option.id.toString() !== wallet.id.toString());

    const [isEditing, setIsEditing] = useState(false);
    const [selectedDestinationWallet, setSelectedDestinationWallet] = useState<SelectOption | null>(null);

    const [verificationWord, setVerificationWord] = useState("");
    const [errorWalletMessage, setErrorWalletMessage] = useState<string | undefined>(undefined);
    const [errorVerificationMessage, setErrorVerificationMessage] = useState<string | undefined>(undefined);

    const [walletTouched, setWalletTouched] = useState(false);
    const [verificationTouched, setVerificationTouched] = useState(false);
    const debouncedVerificationWord = useDebounce(verificationWord, 700);

    useEffect(() => {
        setErrorVerificationMessage(undefined);
        if (debouncedVerificationWord !== "SUPPRIMER" && verificationTouched) {
            setErrorVerificationMessage(t<string>("CommonUse.wrong-verification-words"));
        }
    }, [debouncedVerificationWord, t, verificationTouched]);

    useEffect(() => {
        if (selectedDestinationWallet === null) {
            setErrorWalletMessage(t<string>("Wallets.pick-destination-wallet"));
        } else {
            setErrorWalletMessage(undefined);
        }
    }, [selectedDestinationWallet, t]);

    const handleSetEditing = (editing: boolean) => () => {
        setIsEditing(editing);
    };

    const handleVerificationInput = (value: string) => {
        setVerificationWord(value);
        if (!verificationTouched) setVerificationTouched(true);
    };

    const handleChangeWallet = (option: SelectOption) => {
        setSelectedDestinationWallet(option);
        setWalletTouched(true);
    };

    const handleTogglePasswordModal = () => {
        if (selectedDestinationWallet) {
            handleArchiveWallet(selectedDestinationWallet.id);
        }
    };

    return (
        <>
            <div className='wallet-information-drawer-archive-container'>
                <div className='header'>
                    <Typography message={t("Wallets.delete-definitely")} />
                    <Button
                        label={t("CommonUse.delete")}
                        color='error'
                        variant='ghost'
                        onClick={handleSetEditing(true)}
                    />
                </div>
                <div className='content'>
                    <Alert
                        icon='exclamation-triangle'
                        color='error'
                        message={t("Wallets.if-archived")}
                        subMessage={t<string>("Wallets.archived-description")}
                    />
                    {isEditing && (
                        <>
                            <Alert
                                color='warning'
                                icon='exclamation-circle'
                                message={t("Wallets.if-archived-warning")}
                            />
                            <SimpleSelect
                                useRadio
                                selected={selectedDestinationWallet}
                                label={t<string>("CommonUse.select-destination-account")}
                                placeholder={t<string>("CommonUse.select-destination-account")}
                                options={walletOptions}
                                onSelectionChange={handleChangeWallet}
                                variant={walletTouched && errorWalletMessage ? "error" : "primary"}
                                underMessage={walletTouched && errorWalletMessage ? errorWalletMessage : ""}
                            />

                            <Input
                                label={t<string>("CommonUse.checking")}
                                placeholder={t("CommonUse.verification-words")}
                                value={verificationWord}
                                onChange={handleVerificationInput}
                                underMessage={
                                    verificationTouched && errorVerificationMessage
                                        ? errorVerificationMessage
                                        : t<string>("CommonUse.archive-words", { entity: t("Wallets.a-wallet") })
                                }
                                variant={verificationTouched && errorVerificationMessage ? "error" : "primary"}
                            />
                        </>
                    )}
                </div>
                {isEditing && (
                    <div className='footer'>
                        <Button variant='tertiary' label={t("CommonUse.cancel")} onClick={handleSetEditing(false)} />
                        <Button
                            label={t("CommonUse.archive")}
                            disabled={selectedDestinationWallet === null || verificationWord !== "SUPPRIMER"}
                            onClick={handleTogglePasswordModal}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
