import { FormContainer } from "../FormContainer";
import { toast } from "react-toastify";
import {
    RequestUpdateAuthUserReturn,
    requestUpdateAuthUser,
    requestUpdateAuthUserPhoto,
    requestVerifyEmail,
} from "src/services/auth/operations";
import { UserAuthApiReturn } from "src/services/auth/types";
import { setInStorage } from "src/shared/utils/storage";
import { validateField } from "src/shared/utils/validateField";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { updateAuthUserInformation, updateAuthUserPhoto } from "src/modules/auth/slice";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { LoginEmailVerificationDialog } from "src/components/Login/EmailValidation/Dialog";
import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Input } from "src/shared/atoms/Inputs/Input";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CopyInput } from "src/shared/components/Copy/Input";
import { FileUploaderButton } from "src/shared/components/FileUploader/FileUploaderButton";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

type CheckErrorsProps = { firstname: string; lastname: string; email: string };
type CheckErrorFields = keyof CheckErrorsProps;
type CheckErrorReturn = { [key in CheckErrorFields]?: true };

const checkErrors = (fields: CheckErrorsProps) => {
    return Object.entries(fields).reduce<CheckErrorReturn>((res, [key, value]) => {
        if (!value) {
            res[key as CheckErrorFields] = true;
        }
        return res;
    }, {});
};

const localesOptions: SelectOption[] = [
    {
        label: "English",
        id: "en",
    },
    {
        label: "Français",
        id: "fr",
    },
];

type ProfileSettingsProps = {
    user: UserAuthApiReturn;
    setIsCurrentlyModifying: (isModifying: boolean) => void;
};
export const ProfileSettings = ({ user, setIsCurrentlyModifying }: ProfileSettingsProps) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const authUser = useAppSelector((state) => getAuthUser(state));

    const [isEditMode, setIsEditMode] = useState(false);
    const [open, setOpen] = useState(false);
    const [firstname, setFirstname] = useState(user.firstname ?? "");
    const [lastname, setLastname] = useState(user.lastname ?? "");
    const [email, setEmail] = useState(user.email ?? "");
    const [phone, setPhone] = useState<InputPhoneValue | null>();
    const [errors, setErrors] = useState<CheckErrorReturn>();
    const [activeSave, setActiveSave] = useState(false);
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [errorLabel, setErrorLabel] = useState<{ [key: string]: string }>({});

    const selectedLanguage = localesOptions.find((option) => option.id === i18n.language) || localesOptions[0];
    const handleChange = (option: SelectOption) => {
        i18n.changeLanguage(option.id);
    };

    const handleSendRequest = () => {
        const errors = checkErrors({ firstname, lastname, email });

        if (Object.entries(errors).length === 0) {
            requestUpdateAuthUser({ firstname, lastname, email, phone: phone?.value })
                .then((res: RequestUpdateAuthUserReturn) => {
                    if (authUser) {
                        setInStorage("user", { ...authUser, ...res });
                    }
                    dispatch(updateAuthUserInformation(res));
                    toast.success(t("Profile.information-updated"));
                    setActiveSave(false);
                    handleEditMode();
                })
                .catch((e) => {
                    toast.error(t(e.message));
                });
        } else {
            setErrors(errors);
        }
    };

    const handleSendValidationMail = () => {
        setLoading(true);
        requestVerifyEmail()
            .then(() => setOpen(true))
            .finally(() =>
                setTimeout(() => {
                    setLoading(false);
                }, 30000)
            );
    };

    useEffect(() => {
        const errors = checkErrors({ firstname, lastname, email });

        setActiveSave(
            (firstname !== user.firstname || lastname !== user.lastname || email !== user.email) &&
                Object.entries(errors).length === 0
        );
    }, [firstname, lastname, email, phone, user]);

    useEffect(() => {
        setIsCurrentlyModifying(activeSave);
    }, [activeSave]);

    const handleChangeFirstName = (newFirstName: string) => {
        setFirstname(newFirstName);
    };

    const handleChangeLastName = (newLastName: string) => {
        setLastname(newLastName);
    };

    const handleChangeEmail = (newEmail: string) => {
        setEmail(newEmail);
    };

    const handleChangeTelephone = (newTelephone: InputPhoneValue) => {
        setPhone(newTelephone);
    };

    const handleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const handleCancel = () => {
        setIsEditMode(false);
        setFirstname(authUser?.firstname ?? "");
        setLastname(authUser?.lastname ?? "");
        setEmail(authUser?.email ?? "");
    };

    const handleSendFile = (file: File) => {
        setAvatarLoading(true);
        requestUpdateAuthUserPhoto(file)
            .then((res) => {
                dispatch(updateAuthUserPhoto(res));
                setAvatarLoading(false);
            })
            .finally(() => setAvatarLoading(false));
    };

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                if (activeSave && isEditMode) {
                    handleSendRequest();
                }
            }
        },
        [activeSave, isEditMode, handleSendRequest]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress]);

    const showEmailValidationSuccess = authUser?.isEmailVerified && authUser?.email === email;

    useEffect(() => {
        if (!validateField("common", firstname).isValid) {
            setErrorLabel((prev) => ({ ...prev, firstname: t("CommonUse.no-spec-char") }));
        } else {
            setErrorLabel((prev) => {
                const { firstname, ...rest } = prev;
                return rest;
            });
        }
    }, [firstname]);

    useEffect(() => {
        if (!validateField("common", lastname).isValid) {
            setErrorLabel((prev) => ({ ...prev, lastname: t("CommonUse.no-spec-char") }));
        } else {
            setErrorLabel((prev) => {
                const { lastname, ...rest } = prev;
                return rest;
            });
        }
    }, [lastname]);

    const debouncedEmail = useDebounce(email, 700);

    useEffect(() => {
        console.log("debouncedEmail", debouncedEmail);
        if (debouncedEmail && !validateField("email", debouncedEmail).isValid) {
            setErrorLabel((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
        } else {
            setErrorLabel((prev) => {
                const { email, ...rest } = prev;
                return rest;
            });
        }
    }, [debouncedEmail]);

    const handleBlurEmail = () => {
        if (!validateField("email", email).isValid) {
            setErrorLabel((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
        } else {
            setErrorLabel((prev) => {
                const { email, ...rest } = prev;
                return rest;
            });
        }
    };

    return (
        <div className='profile-settings-tab'>
            <div className='form-contents'>
                <div className='left-content'>
                    <FormContainer
                        testId='edit-user-section'
                        icon={"identification"}
                        title={t("Expense-policy.general-informations")}
                        editMode={{ isEditMode, setIsEditMode: handleEditMode }}
                        children={
                            <div className='profile-settings-form'>
                                <div className='name-content'>
                                    <Input
                                        testId='input-lastname'
                                        inputClassname='background-neutral-100'
                                        label={t("CommonUse.lastname").toString()}
                                        value={firstname ?? ""}
                                        placeholder={t("CommonUse.lastname-placeholder")}
                                        onChange={handleChangeFirstName}
                                        disabled={!isEditMode}
                                        variant={errors?.firstname || errorLabel.firstname ? "error" : undefined}
                                        underMessage={
                                            errors?.firstname
                                                ? t("CommonUse.please-fill-field").toString()
                                                : errorLabel.firstname
                                                ? errorLabel.firstname
                                                : undefined
                                        }
                                    />
                                    <Input
                                        testId='input-name'
                                        inputClassname='background-neutral-100'
                                        label={t("CommonUse.name").toString()}
                                        value={lastname ?? ""}
                                        placeholder={t("CommonUse.name-placeholder")}
                                        onChange={handleChangeLastName}
                                        disabled={!isEditMode}
                                        variant={errors?.lastname || errorLabel.lastname ? "error" : undefined}
                                        underMessage={
                                            errors?.lastname
                                                ? t("CommonUse.please-fill-field").toString()
                                                : errorLabel.lastname
                                                ? errorLabel.lastname
                                                : undefined
                                        }
                                    />
                                </div>
                                <Input
                                    testId='input-email'
                                    inputClassname='background-neutral-100'
                                    label={t("CommonUse.email").toString()}
                                    value={email}
                                    placeholder={t("CommonUse.email-placeholder")}
                                    onChange={handleChangeEmail}
                                    onBlur={handleBlurEmail}
                                    disabled={!isEditMode}
                                    variant={
                                        showEmailValidationSuccess ? "success" : errorLabel.email ? "error" : undefined
                                    }
                                    underMessage={
                                        showEmailValidationSuccess
                                            ? t("LoginPanel.email-verified").toString()
                                            : errorLabel.email
                                            ? errorLabel.email.toString()
                                            : undefined
                                    }
                                />
                                {!authUser?.isEmailVerified && (
                                    <Alert
                                        topBottom
                                        message={t("LoginPanel.email-not-verified")}
                                        subMessage={t<string>("LoginPanel.email-not-verified-description")}
                                        color='warning'
                                        buttons={{
                                            onClick: handleSendValidationMail,
                                            testId: "send-email-button",
                                            className: "w-100",
                                            color: "warning",
                                            disabled: loading,
                                            label: t("LoginPanel.send-email-verification-link"),
                                        }}
                                    />
                                )}
                                <InputPhone
                                    label={t("CommonUse.phone-number").toString()}
                                    value={user.phone ?? ""}
                                    prefix={user.countryCode ?? "00225"}
                                    disabled
                                    placeholder={t("CommonUse.phone-number-placeholder")}
                                    onChange={handleChangeTelephone}
                                />
                            </div>
                        }
                        buttons={{
                            primary: {
                                label: t("CommonUse.validate"),
                                disabled: !activeSave || !isEditMode || Object.keys(errorLabel).length > 0,
                                onClick: handleSendRequest,
                                variant: "primary",
                                testId: "validate-edit-form",
                            },
                            secondary: {
                                label: t("CommonUse.cancel"),
                                disabled: !isEditMode,
                                onClick: handleCancel,
                                variant: "tertiary",
                            },
                        }}
                    />
                    <FormContainer
                        icon={"user-plus"}
                        title={t("Profile.invite-new-people")}
                        children={
                            <div className='referal-settings'>
                                <div className='referal-info'>
                                    <Avatar icon='question-mark-circle' color='lilas-900' backgroundColor='lilas-50' />
                                    <Typography message={t("Profile.referal-code-message")} />
                                </div>
                                <div className='referal-input-container'>
                                    <CopyInput
                                        testId='copy-referal-button'
                                        label={t<string>("CommonUse.referral-code")}
                                        value={user.Company?.reference ?? ""}
                                        copiedContent={t("Profile.copied-content", {
                                            referalCode: user.Company?.reference,
                                        })}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
                <div className='right-content'>
                    <div className='form-container'>
                        <div className='header'>
                            <div className='left-content'>
                                <Avatar icon={"photo"} />
                                <Typography message={t("Profile.edit-profile-picture")} className='fw-bold' />
                            </div>
                        </div>
                        <div className='body'>
                            <div className='profile-picture-section'>
                                {avatarLoading ? (
                                    <Spinner size='lg' />
                                ) : (
                                    <Avatar
                                        firstname={user.firstname}
                                        lastname={user.lastname}
                                        avatar={user.avatar ?? undefined}
                                    />
                                )}
                                <div className='text-content'>
                                    <Typography className='fw-bold' message={t("Profile.profile-picture")} />
                                    <Typography message={t("Profile.accepted-format")} />
                                </div>
                            </div>
                        </div>
                        <div className='footer'>
                            <FileUploaderButton
                                classname='file-upload-button'
                                label={t("Profile.select-picture")}
                                handleOnSubmit={handleSendFile}
                                loading={avatarLoading}
                                acceptedFiles={["image/*"]}
                            />
                        </div>
                    </div>
                    <FormContainer
                        icon={"flag"}
                        title={t("Profile.change-language")}
                        children={
                            <div className='language-selection-container'>
                                <SimpleSelect
                                    testId='select-language'
                                    label={t<string>("Profile.select-language")}
                                    useRadio
                                    options={localesOptions}
                                    onSelectionChange={handleChange}
                                    selected={selectedLanguage}
                                    placeholder={t<string>("Profile.select-language")}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
            <LoginEmailVerificationDialog open={open} setOpen={setOpen} />
        </div>
    );
};
