import { logoutResetStore } from "../auth/Actions";
import { CreateTaskApiPayload } from "src/services/taskItems/operations";
import { TaskItemsListApiReturn } from "src/services/taskItems/types";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { computeArrayToObject } from "src/shared/utils/computeArrayToObject";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TaskItemsState {
    isLoading: boolean;
    fileUploading: { [taskId: string]: { [id: string]: CreateTaskApiPayload } };
    fileUploadingFailed: { [taskId: string]: { [id: string]: CreateTaskApiPayload } };
    isUpdating: { [taskId: string]: { [taskItemId: string]: boolean } };
    data?: { [taskId: string]: { [key: string]: TaskItemTmpModel } };
    count: number;
}

const initialState = {
    isLoading: false,
    isUpdating: {},
    fileUploading: {},
    fileUploadingFailed: {},
    count: 0,
} as TaskItemsState;

export const tasksItemsSlice = createSlice({
    name: "taskItems",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestTaskItems: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        failedRequestTaskItems: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        failedRequestTaskItemUpdate: (
            state,
            { payload }: PayloadAction<{ taskId: number; taskItemIds: (number | string)[] }>
        ) => {
            const { taskId, taskItemIds } = payload;

            const taksItemStatus = taskItemIds.reduce<{ [taskId: string]: false }>((res, id) => {
                return { ...res, [String(id)]: false };
            }, {});

            return {
                ...state,
                isUpdating: {
                    ...state.isUpdating,
                    [taskId]: {
                        ...state.isUpdating[taskId],
                        ...taksItemStatus,
                    },
                },
            };
        },
        requestTaskItemsFileUploading: (
            state,
            { payload }: PayloadAction<{ taskId: number; fileUploading: CreateTaskApiPayload[] }>
        ) => {
            const { fileUploading, taskId } = payload;

            fileUploading.forEach((item, index) => {
                if (!state.fileUploading[taskId]) {
                    state.fileUploading = { ...state.fileUploading, [taskId]: { [String(index)]: item } };
                } else {
                    state.fileUploading[taskId][String(index)] = item;
                }
            });
        },
        failedRequestTaskItemsFileUploading: (state, { payload }: PayloadAction<{ taskId: number }>) => {
            const { taskId } = payload;

            if (state.fileUploading[taskId]) {
                delete state.fileUploading[taskId];
            }
        },
        requestTaskItemUpdate: (
            state,
            { payload }: PayloadAction<{ taskId: number; taskItemIds: (number | string)[] }>
        ) => {
            const { taskId, taskItemIds } = payload;

            const taksItemStatus = taskItemIds.reduce<{ [taskId: string]: true }>((res, id) => {
                return { ...res, [String(id)]: true };
            }, {});

            return {
                ...state,
                isUpdating: {
                    ...state.isUpdating,
                    [taskId]: {
                        ...state.isUpdating[taskId],
                        ...taksItemStatus,
                    },
                },
            };
        },

        receiveUpdateTaskItems: (
            state,
            { payload }: PayloadAction<{ taskId: number | string; taskItems: TaskItemsListApiReturn }>
        ) => {
            const { taskId, taskItems } = payload;

            return {
                ...state,
                isUpdating: {
                    ...state.isUpdating,
                    [taskId]: {
                        ...state.isUpdating[taskId],
                        ...Object.values(payload.taskItems.data).reduce<{ [taskItemId: string]: boolean }>(
                            (res, taskItem) => {
                                res[taskItem.id] = false;
                                return res;
                            },
                            {}
                        ),
                    },
                },
                data: {
                    ...state.data,
                    [taskId]: {
                        ...state.data?.[taskId],
                        ...computeArrayToObject(taskItems.data, (taskItem) => String(taskItem.id)),
                    },
                },
            };
        },
        receiveTaskItems: (
            state,
            { payload }: PayloadAction<{ taskId: number | string; taskItems: TaskItemsListApiReturn }>
        ) => {
            const { taskId, taskItems } = payload;

            return {
                ...state,
                count: taskItems.count,
                isLoading: false,
                data: {
                    ...state.data,
                    [taskId]: computeArrayToObject(taskItems.data, (taskItem) => String(taskItem.id)),
                },
            };
        },
        receiveCreateTasks: (
            state,
            { payload }: PayloadAction<{ taskId: number; taskItems: TaskItemsListApiReturn; taskLoadingIds: string[] }>
        ) => {
            const { taskId, taskItems, taskLoadingIds } = payload;

            state.isLoading = false;
            taskLoadingIds.forEach((id) => {
                if (state.fileUploading[taskId]?.[id]) {
                    delete state.fileUploading[taskId][id];
                }
            });
            state.data = {
                ...state.data,
                [taskId]: {
                    ...state.data?.[taskId],
                    ...computeArrayToObject(taskItems.data, (taskItem) => String(taskItem.id)),
                },
            };
        },
        failedCreateTasksUpload: (
            state,
            {
                payload,
            }: PayloadAction<{
                taskId: number;
                taskItems: {
                    [index: string]: CreateTaskApiPayload;
                };
            }>
        ) => {
            const { taskId, taskItems } = payload;

            Object.keys(taskItems).forEach((id) => {
                if (state.fileUploading[taskId]?.[id]) {
                    delete state.fileUploading[taskId][id];
                }
            });
            state.fileUploadingFailed = {
                ...state.fileUploadingFailed,
                [taskId]: {
                    ...state.fileUploadingFailed?.[taskId],
                    ...taskItems,
                },
            };
        },
        removeFileUploadingTaskItem: (
            state,
            {
                payload,
            }: PayloadAction<{
                taskId: number;
                taskItemId: string;
            }>
        ) => {
            const { taskId, taskItemId } = payload;

            if (state.fileUploadingFailed?.[taskId]?.[taskItemId]) {
                delete state.fileUploadingFailed[taskId]?.[taskItemId];
            }
        },
        removeTaskItem: (state, { payload }: PayloadAction<{ taskId: number; taskItemIds: (string | number)[] }>) => {
            const { taskId, taskItemIds } = payload;
            const taskItems = state.data?.[taskId];

            if (taskItems) {
                const taksItemStatus = taskItemIds.reduce<{ [taskId: string]: true }>((res, id) => {
                    if (state.isUpdating?.[taskId]?.[id]) {
                        delete state.data?.[taskId]?.[id];
                    }
                    return { ...res, [String(id)]: true };
                }, {});

                state.isLoading = false;
                state.isUpdating = {
                    ...state.isUpdating,
                    [taskId]: {
                        ...state.isUpdating[taskId],
                        taksItemStatus,
                    },
                };
            }
        },
    },
});

export const {
    requestTaskItems,
    receiveUpdateTaskItems,
    requestTaskItemUpdate,
    requestTaskItemsFileUploading,
    failedRequestTaskItemsFileUploading,
    failedRequestTaskItemUpdate,
    failedRequestTaskItems,
    failedCreateTasksUpload,
    receiveCreateTasks,
    removeTaskItem,
    removeFileUploadingTaskItem,
    receiveTaskItems,
} = tasksItemsSlice.actions;

export const tasksItemsSliceReducer = tasksItemsSlice.reducer;
