import { ReactNode } from "react";

import { BillingGetPaidPage } from "./pages/Billing/GetPaid";
import { BillingPayBillPage } from "./pages/Billing/PayBill";
import { BillingPayBillListPage } from "./pages/Billing/PayBillList";
import { BulkTransfertPage } from "./pages/BulkTransfert";
import { CollectPage } from "./pages/Collect";
import { CreateTaskPage } from "./pages/CreateTask";
import { DashboardPage } from "./pages/Dashboard";
import { EditTaskPage } from "./pages/EditTask";
import { ExportPage } from "./pages/Export";
import { ExportTemplatesPage } from "./pages/ExportTemplates";
import { LoanPage } from "./pages/Loan/Loan";
import { LoanTransfertPage } from "./pages/Loan/LoanTransfert";
import { RechargementPage } from "./pages/Rechargement";
import { SignatureTaskPage } from "./pages/SignatureTask";
import { TasksPage } from "./pages/Tasks";
import { TransfertPage } from "./pages/Transfert";
import { TwoFactorPage } from "./pages/TwoFactor";
import { WalletHistoryPage } from "./pages/WalletHistory";
import { AdminAccountActivityPage } from "./pages/admin/ActivityLogs";
import { AdminBeneficiariesPage } from "./pages/admin/Beneficiaries";
import { AdminDashboardPage } from "./pages/admin/Dashboard";
import { AdminDocuments } from "./pages/admin/Documents";
import { AdminExpensePolicy } from "./pages/admin/ExpensePolicy";
import { AdminUsersListPage } from "./pages/admin/Users";
import { AdminWalletCreationPage } from "./pages/admin/WalletCreation";
import { AdminWalletEditPage } from "./pages/admin/WalletEdit";
import { AdminWalletsPage } from "./pages/admin/Wallets";
import { PayBillPaymentPanel } from "src/components/Billing/PayBill/Payment/Panel";
import {
    AdminAccountActivityBreadcrumb,
    AdminBeneficiariesBreadcrumb,
    AdminBreadcrumb,
    AdminDocumentsBreadcrumb,
    AdminExpensePolicyBreadcrumb,
    AdminMyCompanyBreadcrumb,
    AdminUsersBreadcrumb,
    AdminWalletCreateBreadcrumb,
    AdminWalletEditBreadcrumb,
    AdminWalletsBreadcrumb,
} from "src/components/Layout/Breadcrumb/utils/admin";
import {
    BillingPayBreadcrumb,
    BulkTransfertBreadcrumb,
    CollectBreadcrumb,
    CreateTasksBreadcrumb,
    DashboardBreadcrumb,
    ExportBreadcrumb,
    ExportTemplatesBreadcrumb,
    HistoryWalletTaskBreadcrumb,
    LoanBreadcrumb,
    LoanTransfertBreadcrumb,
    ProfileBreadcrumb,
    RechargementBreadcrumb,
    TaskEditBreadcrumb,
    TasksBreadcrumb,
    TransfertBreadcrumb,
} from "src/components/Layout/Breadcrumb/utils/user";
import { UserSettings } from "src/components/UserSettings/Panel";

export type RouteItem = {
    path: string;
    element: ReactNode;
    breadcrumbElement?: ReactNode;
};

export const AdminRoutes: RouteItem[] = [
    {
        path: "/admin",
        element: <AdminDashboardPage />,
        breadcrumbElement: <AdminBreadcrumb />,
    },
    {
        path: "/admin/wallets",
        element: <AdminWalletsPage />,
        breadcrumbElement: <AdminWalletsBreadcrumb />,
    },
    {
        path: "/admin/wallets/create",
        element: <AdminWalletCreationPage />,
        breadcrumbElement: <AdminWalletCreateBreadcrumb />,
    },
    {
        path: "/admin/wallets/:walletId/edit",
        element: <AdminWalletEditPage />,
        breadcrumbElement: <AdminWalletEditBreadcrumb />,
    },
    {
        path: "/admin/users",
        element: <AdminUsersListPage />,
        breadcrumbElement: <AdminUsersBreadcrumb />,
    },
    {
        path: "/admin/beneficiaries",
        element: <AdminBeneficiariesPage />,
        breadcrumbElement: <AdminBeneficiariesBreadcrumb />,
    },
    {
        path: "/admin/documents",
        element: <AdminDocuments />,
        breadcrumbElement: <AdminDocumentsBreadcrumb />,
    },
    {
        path: "/admin/expense-policy",
        element: <AdminExpensePolicy />,
        breadcrumbElement: <AdminExpensePolicyBreadcrumb />,
    },
    {
        path: "/admin/activity-logs",
        element: <AdminAccountActivityPage />,
        breadcrumbElement: <AdminAccountActivityBreadcrumb />,
    },
];

export const UserRoutes: RouteItem[] = [
    {
        path: "/dashboard",
        element: <DashboardPage />,
        breadcrumbElement: <DashboardBreadcrumb />,
    },
    {
        path: "/settings",
        element: <UserSettings />,
        breadcrumbElement: <ProfileBreadcrumb />,
    },
    {
        path: "/settings/2fa",
        element: <TwoFactorPage />,
        breadcrumbElement: <ProfileBreadcrumb />,
    },
    {
        path: "/tasks",
        element: <TasksPage />,
        breadcrumbElement: <TasksBreadcrumb />,
    },
    {
        path: "history/wallet/:walletId/task/:taskId",
        element: <WalletHistoryPage />,
        breadcrumbElement: <HistoryWalletTaskBreadcrumb />,
    },
    {
        path: "/create-task",
        element: <CreateTaskPage />,
        breadcrumbElement: <CreateTasksBreadcrumb />,
    },
    {
        path: "/tasks/:taskId/edit",
        element: <EditTaskPage />,
        breadcrumbElement: <TaskEditBreadcrumb />,
    },
    {
        path: "/tasks/signature/:taskId",
        element: <SignatureTaskPage />,
        breadcrumbElement: <TaskEditBreadcrumb />,
    },
    {
        path: "/tasks-list",
        element: <TasksPage />,
        breadcrumbElement: <TasksBreadcrumb />,
    },
    {
        path: "/loan/my-loans",
        element: <LoanPage />,
        breadcrumbElement: <LoanBreadcrumb />,
    },
    {
        path: "/transfert/loan/:loanId",
        element: <LoanTransfertPage />,
        breadcrumbElement: <LoanTransfertBreadcrumb />,
    },
    {
        path: "/transfert/rapid-transfert",
        element: <TransfertPage />,
        breadcrumbElement: <TransfertBreadcrumb />,
    },
    {
        path: "/transfert/bulk-transfert",
        element: <BulkTransfertPage />,
        breadcrumbElement: <BulkTransfertBreadcrumb />,
    },
    {
        path: "/transfer/collect",
        element: <CollectPage />,
        breadcrumbElement: <CollectBreadcrumb />,
    },

    {
        path: "/tasks-list",
        element: <TasksPage />,
        breadcrumbElement: <TasksBreadcrumb />,
    },
    {
        path: "/transfer/bank-disposal",
        element: <RechargementPage />,
        breadcrumbElement: <RechargementBreadcrumb />,
    },
    {
        path: "/billing/pay-bill-list",
        element: <BillingPayBillListPage />,
        breadcrumbElement: <BillingPayBreadcrumb />,
    },
    {
        path: "/billing/pay-bill",
        element: <BillingPayBillPage />,
        breadcrumbElement: <BillingPayBreadcrumb />,
    },
    {
        path: "/billing/get-paid",
        element: <BillingGetPaidPage />,
        breadcrumbElement: <AdminMyCompanyBreadcrumb />,
    },
    {
        path: "/billing/pay-bill/:billerReference",
        element: <PayBillPaymentPanel />,
        breadcrumbElement: <BillingPayBreadcrumb />,
    },
    {
        path: "/export",
        element: <ExportPage />,
        breadcrumbElement: <ExportBreadcrumb />,
    },
    {
        path: "/export/models",
        element: <ExportTemplatesPage />,
        breadcrumbElement: <ExportTemplatesBreadcrumb />,
    },
];
