import { validateField } from "src/shared/utils/validateField";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import { TFunction } from "i18next";

import { CreateBeneficiaryPayload } from "src/components/Admin/Beneficiaries/CreateDrawer/Container";

const requiredHeaderFields = [
    "Prenom",
    "Nom",
    "Identifiant mobile money",
    "Identifiant IBAN",
    "Identifiant Djamo",
    "Identifiant Wave",
    "Email (facultatif)",
    "Label (facultatif)",
];

export function checkItemFields(item: Partial<CreateBeneficiaryPayload>) {
    const hasRequiredNameFields = item.firstname && item.lastname;
    const hasAtLeastOneIdentifier = item.externalAccounts && item.externalAccounts.length > 0;

    return hasRequiredNameFields && hasAtLeastOneIdentifier;
}

function computeMissingData(item: Partial<CreateBeneficiaryPayload>, index: number) {
    const missingData: { error: string; line: number } = { line: index + 2, error: "" };

    if (!item.firstname || item.firstname === "") {
        missingData.error = "CommonUse.firstname";
    }

    if (!item.lastname || item.lastname === "") {
        missingData.error = "CommonUse.lastname";
    }

    if (!item.externalAccounts || item.externalAccounts.length === 0) {
        missingData.error = "CommonUse.identifier";
    }

    return missingData;
}

type Header = {
    firstname?: number;
    lastname?: number;
    mobileMoney?: number;
    iban?: number;
    djamo?: number;
    wave?: number;
    email?: number;
    label?: number;
};

type ExternalAccountForm = {
    externalReference: string;
    typeSlug: string;
    uuid: string;
};

type ExternalAccounts = {
    wave: "wave-transfer";
    mobileMoney: "disposal";
    iban: "bank-transfer";
    djamo: "cb-transfer";
};

const externalAccounts: ExternalAccounts = {
    wave: "wave-transfer",
    mobileMoney: "disposal",
    iban: "bank-transfer",
    djamo: "cb-transfer",
};

function parseHeaderColumnIndexes(dataParse: string[][]) {
    const header = dataParse?.splice(0, 1)?.pop();

    if (
        !header ||
        header.length !== requiredHeaderFields.length ||
        !requiredHeaderFields.every((field, index) => field === header[index])
    ) {
        return null;
    }

    return header?.reduce<Header>((acc, item, idx) => {
        if (item.includes("Prenom")) acc.firstname = idx;
        if (item.includes("Nom")) acc.lastname = idx;
        if (item.includes("Identifiant mobile money")) acc.mobileMoney = idx;
        if (item.includes("Identifiant IBAN")) acc.iban = idx;
        if (item.includes("Identifiant Djamo")) acc.djamo = idx;
        if (item.includes("Identifiant Wave")) acc.wave = idx;
        if (item.includes("Email (facultatif)")) acc.email = idx;
        if (item.includes("Label (facultatif)")) acc.label = idx;
        return acc;
    }, {});
}

function formatExternalAccounts(row: string[], headerColumnIndexes: Header): ExternalAccountForm[] {
    const accounts: ExternalAccountForm[] = [];
    Object.entries(externalAccounts).forEach(([key, typeSlug]) => {
        const index = headerColumnIndexes[key as keyof Header];
        if (index !== undefined && row[index]) {
            let externalReference = row[index].toString();
            if (key === "wave" || key === "mobileMoney" || key === "djamo") {
                externalReference = externalReference.replace(/\D/g, "");
            }
            externalReference = externalReference.replace(/[!@#$%^&*()]/g, "");

            accounts.push({
                externalReference: externalReference,
                typeSlug: typeSlug,
                uuid: uuidv4(),
            });
        }
    });

    return accounts;
}

function parseRows(dataParse: string[][], headerColumnIndexes: Header) {
    return dataParse.map<Partial<CreateBeneficiaryPayload>>((row) => {
        const obj: Partial<CreateBeneficiaryPayload> = {};

        Object.entries(headerColumnIndexes).forEach(([key, columnIndex]) => {
            const typedKey = key as keyof CreateBeneficiaryPayload;

            if (typedKey === "externalAccounts") {
                return;
            } else {
                const value = row[columnIndex as number];
                if (value !== undefined && value !== "") {
                    obj[typedKey] = value.toString();
                }
            }
        });

        obj.externalAccounts = formatExternalAccounts(row, headerColumnIndexes);

        Object.keys(externalAccounts).forEach((key) => {
            delete obj[key as keyof CreateBeneficiaryPayload];
        });

        return obj;
    });
}

export const parseBeneficiaries = (t: TFunction<"translation", string, "translation">, data: string | ArrayBuffer) => {
    const readedData = XLSX.read(data, { type: "binary" });
    const dataSheet = Object.values(readedData?.Sheets)?.[0];

    if (readedData.SheetNames.length !== 1) {
        return t("Tasks.dont-add-sheets");
    }

    if (dataSheet) {
        const dataParse = XLSX.utils.sheet_to_json<string[]>(dataSheet, { header: 1 });
        const headerColumnIndexes = parseHeaderColumnIndexes(dataParse);

        if (!headerColumnIndexes) {
            return t("Tasks.excel-file-wrong-header");
        }

        const rowsFormatted = parseRows(dataParse, headerColumnIndexes);

        let missingData: { line: number; error: string } = { line: 1, error: "" };

        const checkRowContainsRequiredData = rowsFormatted
            .filter((item) => {
                const nonEmptyValues = Object.values(item).filter((value) => value !== "");
                return nonEmptyValues.length > 1;
            })
            .every((item, index) => {
                const check = checkItemFields(item);

                if (!check) {
                    missingData = computeMissingData(item, index);
                }

                if (check) {
                    if (item.lastname && !validateField("common", item.lastname).isValid) {
                        missingData = { line: index + 2, error: "CommonUse.no-spec-char" };
                        return false;
                    }
                    if (item.firstname && !validateField("common", item.firstname).isValid) {
                        missingData = { line: index + 2, error: "CommonUse.no-spec-char" };
                        return false;
                    }
                    if (item.email && !validateField("email", item.email).isValid) {
                        missingData = { line: index + 2, error: "CommonUse.no-spec-char-email" };
                        return false;
                    }
                    if (item.label && !validateField("common", item.label).isValid) {
                        missingData = { line: index + 2, error: "CommonUse.no-spec-char" };
                        return false;
                    }
                }
                return check;
            });

        if (!checkRowContainsRequiredData) {
            if (missingData.error === "Tasks.excel-rows-wrong-data-beneficiary-name") {
                return t(missingData.error);
            }
            return t("Tasks.excel-rows-wrong-data", {
                line: missingData.line,
                error: t(missingData.error),
            });
        }

        const filteredRowsFormatted = rowsFormatted.filter((item) => {
            const nonEmptyValues = Object.values(item).filter((value) => value !== "");
            return nonEmptyValues.length > 1;
        });

        return filteredRowsFormatted.length === 0 ? null : filteredRowsFormatted;
    } else {
        return null;
    }
};
