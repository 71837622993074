import { EditTaskInputCell } from "./InputCell";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { handleUpdateTaskItem } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    taskId?: number;
    taskItem: TaskItemTmpModel;
    loading: boolean;
};

export function TasksItemEditFieldAmount({ taskItem, taskId, loading }: Props) {
    const dispatch = useAppDispatch();

    const handleUpdateAmount = (newAmount: string) => {
        if (taskId && parseInt(newAmount) !== taskItem.amount) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItem,
                    update: {
                        amount: parseInt(newAmount),
                    },
                })
            ).then(() => dispatch(fetchTaskToEdit({ taskId })));
        }
    };

    return (
        <EditTaskInputCell
            disabled={loading}
            initialValue={String(taskItem.amount)}
            onValidate={handleUpdateAmount}
            type='number'
            testId='edit-task-row-amount-input'
        >
            <Typography
                message={formatMoneyToString({ amount: taskItem.amount ?? 0 })}
                className='edit-task-table-cell-content'
            />
        </EditTaskInputCell>
    );
}
