import classNames from "classnames";
import { validateField } from "src/shared/utils/validateField";

import { InputHTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "src/shared/atoms/Inputs/Input";

type Props = {
    initialValue: string;
    onValidate?: (value: string) => void;
    editOpen?: boolean;
    testId?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function EditTaskInputCell({
    initialValue,
    onValidate,
    disabled,
    children,
    editOpen,
    testId,
    placeholder = "",
    type,
}: PropsWithChildren<Props>) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(editOpen ?? false);
    const [value, setValue] = useState(initialValue);
    const [valueError, setValueError] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setEdit(editOpen ?? false);
    }, [editOpen]);

    useEffect(() => {
        if (inputRef) {
            inputRef.current?.focus();
        }
    }, [edit, inputRef]);

    useEffect(() => {
        setValue(initialValue !== "null" ? initialValue : "");
    }, [initialValue]);

    useEffect(() => {
        setValueError(!validateField("common", value).isValid);
    }, [value]);

    const handleChange = (change: string) => {
        setValue(change);
    };

    const setEditOn = () => {
        setEdit(true);
    };

    const handleValidate = () => {
        if (edit) {
            setEdit(false);
            if (onValidate) {
                onValidate(value);
            }
        }
    };

    const handleValidateKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleValidate();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleValidateKeyDown);

        return () => {
            document.removeEventListener("keydown", handleValidateKeyDown);
        };
    }, [edit, value]);

    return (
        <>
            {edit ? (
                <Input
                    autoFocus
                    type={type}
                    placeholder={placeholder}
                    value={value ?? 0}
                    onChange={handleChange}
                    onBlur={handleValidate}
                    disabled={disabled}
                    data-testid={testId}
                    className='input-cell-edit-task'
                    variant={valueError ? "error" : undefined}
                    underMessage={valueError ? t<string>("CommonUse.no-spec-char") : undefined}
                />
            ) : (
                <div
                    onClick={disabled ? undefined : setEditOn}
                    className={classNames("d-flex align-items-center edit-task-table-cell-input", {
                        "content-disabled": disabled,
                    })}
                >
                    <div className='mr-2'>{children}</div>
                </div>
            )}
        </>
    );
}
