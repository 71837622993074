import { BillingEnterpriseFieldData, BillingEnterpriseFieldNames } from "src/services/billing/enterprise/type";
import { retrieveNumberFromPattern } from "src/shared/utils/retrieveNumberFromPattern";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const fieldTypeTranslations = {
    payerReference: "Bills.client-reference",
    invoiceReference: "Bills.bill-reference",
};

type Props = {
    fieldType: BillingEnterpriseFieldNames;
    field: BillingEnterpriseFieldData;
    edit: "details" | "invoiceReceipt" | null;
    errors: { [key: string]: string };
    setFields?: (fieldType: BillingEnterpriseFieldNames, update: Partial<BillingEnterpriseFieldData>) => void;
};

export function BillingGetPaidDrawerSectionDetailsFields({ edit, field, fieldType, setFields, errors }: Props) {
    const { t } = useTranslation();

    const [maximumLength, setMaximumLength] = useState<number | null>(retrieveNumberFromPattern(field.patern));

    const { display, label, patern, required } = field;

    const handleSetFields =
        (fieldName: keyof Props["field"], pattern = "") =>
        (value?: string) => {
            if (setFields) {
                switch (fieldName) {
                    case "display": {
                        if (!field[fieldName] === false) {
                            setFields(fieldType, { required: false, [fieldName]: !field[fieldName] });
                        } else {
                            setFields(fieldType, { [fieldName]: !field[fieldName] });
                        }
                        break;
                    }
                    case "required": {
                        setFields(fieldType, { [fieldName]: !field[fieldName] });
                        break;
                    }
                    case "label": {
                        setFields(fieldType, { [fieldName]: value ?? "" });
                        break;
                    }
                    case "patern": {
                        const paternNumber = maximumLength && maximumLength > 0 ? `{${maximumLength}}` : "";
                        const newPatern = field.patern.includes(pattern)
                            ? field.patern.replace(pattern, "")
                            : `[${field.patern.replace(/{.*}|\[|\]/g, "")}${pattern}]${paternNumber}`;
                        setFields(fieldType, { [fieldName]: newPatern });
                        break;
                    }
                }
            }
        };

    const handleSetMaximumLenght = (value: string) => {
        const newValue = parseInt(value);
        if (setFields) {
            setMaximumLength(newValue);
            const paternNumber = newValue > 0 ? `{${newValue}}` : "";
            setFields(fieldType, { patern: `[${field.patern.replace(/{.*}|\[|\]/g, "")}]${paternNumber}` });
        }
    };

    const renderVariant = errors[fieldType] ? "error" : undefined;
    const renderUnderMessage = errors[fieldType] ?? undefined;

    return edit === "details" ? (
        <>
            <div className='divider' />
            <div className='bill-field-container'>
                <div className='bill-field-header'>
                    <div className='d-flex flex-column'>
                        <Typography message={t("Bills.initial-field")} />
                        <Typography className='fw-bold' message={t(fieldTypeTranslations[fieldType])} />
                    </div>
                    <div className='divider' />
                    <div className='bill-field'>
                        <Switch onToggle={handleSetFields("display") as () => void} isOn={field.display} />
                        <Typography className='fw-bold' message={t("Bills.show-field")} />
                    </div>
                </div>
                {field.display ? (
                    <>
                        <Input
                            label={t<string>("Bills.field-title")}
                            inputClassname='background-neutral-100'
                            value={label}
                            maxLength={50}
                            onChange={handleSetFields("label")}
                            placeholder={t<string>("Bills.field-title")}
                            variant={renderVariant}
                            underMessage={renderUnderMessage}
                        />
                        <Checkbox
                            onChange={handleSetFields("required") as () => void}
                            label={<Typography className='fw-bold' message={t("Bills.fill-field-required")} />}
                            checked={required}
                        />
                        <Typography className='fw-bold' message={t("Bills.field-payload-format")} />
                        <Checkbox
                            onChange={handleSetFields("patern", "a-zA-Z") as () => void}
                            label={<Typography className='fw-bold' message={t("Bills.field-characters")} />}
                            checked={patern.includes("a-zA-Z")}
                        />
                        <Checkbox
                            onChange={handleSetFields("patern", "0-9") as () => void}
                            label={<Typography className='fw-bold' message={t("Bills.field-numbers")} />}
                            checked={patern.includes("0-9")}
                        />
                        <Checkbox
                            onChange={handleSetFields("patern", "-_.+*?") as () => void}
                            label={<Typography className='fw-bold' message={t("Bills.field-special-characters")} />}
                            checked={patern.includes("-_.+*?")}
                        />
                        {!patern.includes("a-zA-Z") && !patern.includes("0-9") ? (
                            <div className='d-flex align-items-center'>
                                <Icon name='x-circle-filled' color='error' />
                                <Typography
                                    message={t("Bills.error-field-pattern")}
                                    className='ml-2 fw-bold color-error-500'
                                />
                            </div>
                        ) : null}
                        <Input
                            label={t<string>("Bills.maximum-characters-allowed")}
                            type='number'
                            min={1}
                            value={String(maximumLength)}
                            onChange={handleSetMaximumLenght}
                            placeholder={t<string>("Bills.maximum-characters-allowed-example")}
                        />
                    </>
                ) : null}
            </div>
        </>
    ) : (
        <>
            <div className='divider' />
            <div className='bill-field-container'>
                <div className='bill-field-header'>
                    <div className='d-flex flex-column'>
                        <Typography message={t("Bills.field")} />
                        <Typography className='fw-bold' message={label} />
                    </div>
                    <div className='divider' />
                    <div className='bill-field'>
                        <Switch disabled isOn={display} />
                        <Typography className='fw-bold' message={t("Bills.show-field")} />
                    </div>
                </div>
                {field.display ? (
                    <>
                        <Checkbox
                            label={<Typography className='fw-bold' message={t("Bills.field-characters")} />}
                            disabled
                            checked={patern.includes("a-zA-Z")}
                        />
                        <Checkbox
                            label={<Typography className='fw-bold' message={t("Bills.field-numbers")} />}
                            disabled
                            checked={patern.includes("0-9")}
                        />
                        <Checkbox
                            label={<Typography className='fw-bold' message={t("Bills.field-special-characters")} />}
                            disabled
                            checked={patern.includes("-_.+*?")}
                        />
                        {maximumLength ? (
                            <div className='d-flex'>
                                <Typography
                                    className='fw-bold color-neutral-500'
                                    message={t("Bills.maximum-characters")}
                                />
                                <Typography className='fw-bold ml-2' message={maximumLength} />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        </>
    );
}
