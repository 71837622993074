import { BankDisposalFormType } from "../Panel";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";

import { useTranslation } from "react-i18next";

import { Input } from "src/shared/atoms/Inputs/Input";
import { AmountInput } from "src/shared/components/AmountInput/AmountInput";

import "./styles.scss";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    error: string | null;
    commentError: string | null;
};

export const RechargementAmountContainer = ({ form, error, commentError }: Props) => {
    const { t } = useTranslation();

    const handleSetAmount = (value: number) => {
        form.setFieldValue("amount", value);
    };

    const handleSetComment = (value: string) => {
        form.setFieldValue("comment", value);
    };

    return (
        <div className='amount-container'>
            <AmountInput
                label={t("DisposalInfoContainer.montant-a-recharger")}
                name='amount'
                autoFocus
                placeholder='0 FCFA'
                value={form.values.amount}
                handleChange={handleSetAmount}
                variant={error ? "error" : undefined}
                underMessage={error}
            />

            <Input
                placeholder={t("DisposalInfoContainer.ex-remboursement")}
                onChange={handleSetComment}
                value={form.values.comment}
                variant={commentError ? "error" : undefined}
                underMessage={commentError ? commentError : undefined}
                className='search-input'
            />
        </div>
    );
};
