import { DashboardListItem } from "../Dashboard/ListItem";
import { CreateExportDrawer } from "../Export/Drawers/Export/CreateDrawer";
import { HistoryWalletDrawerContainer } from "./Wallet/Drawer/Container";
import { HistoryWalletFilters } from "./Wallet/Filters";
import { useLocation } from "react-router-dom";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getJulayaWallets, getWallet } from "src/modules/wallets/selectors";
import { fetchWallet } from "src/modules/wallets/thunkActions";
import {
    getAllWalletsTransactions,
    getAllWalletsTransactionsCount,
    getAllWalletsTransactionsCurrentPage,
    getAllWalletsTransactionsLoading,
    getAllWalletsTransactionsPageSize,
    getWalletTransactionFilters,
    getWalletTransactions,
    getWalletTransactionsCount,
    getWalletTransactionsCurrentPage,
    getWalletTransactionsIsActiveFilters,
    getWalletTransactionsLoading,
    getWalletTransactionsQueryFilter,
    getWalletTransactionsShowPageSize,
} from "src/modules/walletsTransactions/selectors";
import {
    setAllWalletsTransactionsCurrentPage,
    setAllWalletsTransactionsPageSize,
    setWalletTransactionCurrentPage,
    setWalletTransactionPageSize,
} from "src/modules/walletsTransactions/slice";
import {
    fetchWalletTransactions,
    handleRequestCompaniesTransactions,
} from "src/modules/walletsTransactions/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransactionHistoryTable } from "src/components/History/Table";
import { Button } from "src/shared/atoms/Buttons/Button";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { TableColumnHider, TableColumns } from "src/shared/components/TableColumnsHider/TableColumnHider";

import "./styles.scss";

export type HistoryWalletStateFilters = {
    status?: string[];
    query?: string | undefined;
    minDate?: string | null;
    maxDate?: string | null;
    page: number;
    transactionType?: number[] | undefined;
};

type WalletHistoryPanelProps = {
    propWalletId: string | null;
    displayHeader?: boolean;
    displayFilters?: boolean;
    handleSetIsLoadingHistory?: (isLoading: boolean) => void;
    displayWalletColumn?: boolean;
};

export const WalletHistoryPanel = ({
    propWalletId,
    displayHeader = true,
    displayFilters = true,
    handleSetIsLoadingHistory,
    displayWalletColumn = false,
}: WalletHistoryPanelProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const location = useLocation();
    const { taskId: routeTaskId, routeWalletId } = location.state || {};

    const walletId = propWalletId || routeWalletId || null;

    const wallet = useAppSelector((state) => getWallet(state, { walletId: parseInt(walletId) }));

    const filters = useAppSelector((state) => getWalletTransactionFilters(state));
    const isLoading = useAppSelector((state) => getWalletTransactionsLoading(state, { walletId }));
    const totalCount = useAppSelector((state) => getWalletTransactionsCount(state, { walletId }));
    const transactions = useAppSelector((state) => getWalletTransactions(state, { walletId }));
    const currentPage = useAppSelector((state) => getWalletTransactionsCurrentPage(state, { walletId }));
    const pageSize = useAppSelector((state) => getWalletTransactionsShowPageSize(state, { walletId }));

    const allWalletsTransactions = useAppSelector((state) => getAllWalletsTransactions(state)) ?? [];
    const allWalletsTransactionsCount = useAppSelector((state) => getAllWalletsTransactionsCount(state));
    const allWalletsTransactionsLoading = useAppSelector((state) => getAllWalletsTransactionsLoading(state));
    const allWalletsTransactionsPageSize = useAppSelector((state) => getAllWalletsTransactionsPageSize(state));
    const allWalletsTransactionsCurrentPage = useAppSelector((state) => getAllWalletsTransactionsCurrentPage(state));

    const activeFilters = useAppSelector((state) => getWalletTransactionsIsActiveFilters(state));

    const query = useAppSelector((state) => getWalletTransactionsQueryFilter(state));
    const debouncedQuery = useDebounce(query, 700);

    const [selectedTransactionUuid, setSelectedTransactionUuid] = useState<string | null>(null);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const wallets = useAppSelector((state) => getJulayaWallets(state));
    const walletsIds = wallets.map((wallet) => wallet.id);

    const initialColumns: TableColumns[] = [
        { id: "payment", label: t("Transactions.receiver"), isVisible: true, isEditable: false },
        { id: "date", label: t("CommonUse.date"), isVisible: true, isEditable: false },
        { id: "status", label: t("CommonUse.statut"), isVisible: true, isEditable: false },
        { id: "amount", label: t("CommonUse.net-amount"), isVisible: true, isEditable: false },
        { id: "operationType", label: t("Transactions.transaction-type"), isVisible: true, isEditable: true },
        { id: "userReference", label: t("Users.user-reference"), isVisible: false, isEditable: true },
        { id: "comment", label: t("CommonUse.comment"), isVisible: true, isEditable: true },
    ];

    const [columns, setColumns] = useState(initialColumns);

    const visibleColumnNames = columns.filter((col) => col.isVisible).map((col) => col.label);

    useEffect(() => {
        if (walletId !== null) {
            dispatch(fetchWallet({ walletId }));
        }
    }, [dispatch, fetch, walletId]);

    useEffect(() => {
        if (handleSetIsLoadingHistory) {
            handleSetIsLoadingHistory(isLoading);
        }
    }, [isLoading]);

    useEffect(() => {
        if (transactions && !isLoading && routeTaskId) {
            const openDrawer = transactions?.find((transaction) => transaction.uuid === routeTaskId);
            if (openDrawer) {
                setSelectedTransactionUuid(routeTaskId);
            }
        }
    }, [isLoading, routeTaskId, transactions]);

    const handleOpenDrawer = (transactionId: string | null) => () => {
        setSelectedTransactionUuid(transactionId);
    };

    const handleCloseDrawer = () => {
        setSelectedTransactionUuid(null);
    };

    useEffect(() => {
        if (!isLoading && walletId !== null) {
            handleFetchWalletTransactions();
        }
    }, [currentPage, pageSize, walletId, debouncedQuery]);

    useEffect(() => {
        if (!allWalletsTransactionsLoading && walletId === null) {
            handleFetchAllWAlletTransactions();
        }
    }, [allWalletsTransactionsPageSize, allWalletsTransactionsCurrentPage, debouncedQuery]);

    const handleFetchWalletTransactions = () => {
        dispatch(
            fetchWalletTransactions({
                walletId,
                filters: { ...filters, ...(routeTaskId ? { tasks: [{ id: routeTaskId.toString(), label: "" }] } : {}) },
                page: currentPage,
                pageSize: pageSize,
            })
        );
    };

    const handleFetchAllWAlletTransactions = () => {
        dispatch(
            handleRequestCompaniesTransactions({
                filters: { ...filters, ...(routeTaskId ? { tasks: [{ id: routeTaskId.toString(), label: "" }] } : {}) },
                page: allWalletsTransactionsCurrentPage,
                pageSize: allWalletsTransactionsPageSize,
            })
        );
    };

    const handleRedirectToTopUp = () => {
        setOpen("topUp");
        setProvidedState({ walletId: walletId, sendStateOnRedirection: true });
    };

    const handleOpenCreateDrawer = () => {
        setCreateDrawerOpen(!createDrawerOpen);
    };

    const handleSetCurrentPage = (newPage: number) => {
        dispatch(
            walletId !== null
                ? setWalletTransactionCurrentPage({ walletId, currentPage: newPage })
                : setAllWalletsTransactionsCurrentPage({ currentPage: newPage })
        );
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(
            walletId !== null
                ? setWalletTransactionPageSize({ walletId, pageSize: newPageSize })
                : setAllWalletsTransactionsPageSize({ pageSize: newPageSize })
        );
    };

    return (
        <div className='transaction-history-panel'>
            <HistoryWalletDrawerContainer
                walletId={walletId ?? ""}
                selectedTransactionUuid={selectedTransactionUuid}
                selectedTransaction={
                    selectedTransactionUuid && walletId === null
                        ? allWalletsTransactions.find((t) => t.uuid === selectedTransactionUuid)
                        : undefined
                }
                handleCloseDrawer={handleCloseDrawer}
            />
            <div className='wallet-panel-container'>
                {displayFilters && (
                    <PageHeader>
                        <div className='wallet-panel-page-header'>
                            {displayHeader && (
                                <div className='history-list-item'>
                                    <DashboardListItem icon='wallet' wallet={wallet} />
                                </div>
                            )}
                            <div className='wallet-panel-header-container'>
                                <div className='wallet-header-left'>
                                    <HistoryWalletFilters taskId={routeTaskId} walletId={walletId} />
                                    <TableColumnHider initialColumns={initialColumns} setColumns={setColumns} />
                                </div>
                                <div className='button-container'>
                                    <Button
                                        disabled={!wallet?.canTopUp || !wallet}
                                        onClick={handleRedirectToTopUp}
                                        icon='wallet'
                                        label={t("Transactions.top-up-wallet")}
                                        variant='secondary'
                                    />
                                    <Button
                                        onClick={handleOpenCreateDrawer}
                                        icon='arrow-down-on-square'
                                        label={t("Export.export-view")}
                                        variant='secondary'
                                    />
                                </div>
                            </div>
                        </div>
                    </PageHeader>
                )}
                <TransactionHistoryTable
                    transactions={walletId !== null ? transactions : allWalletsTransactions}
                    handleOpenDrawer={handleOpenDrawer}
                    currentPage={walletId !== null ? currentPage : allWalletsTransactionsCurrentPage}
                    showPageSize={walletId !== null ? pageSize : allWalletsTransactionsPageSize}
                    totalCount={walletId !== null ? totalCount : allWalletsTransactionsCount}
                    setCurrentPage={handleSetCurrentPage}
                    setPageSize={handleSetPageSize}
                    handleFetchWalletTransactions={
                        walletId !== null ? handleFetchWalletTransactions : handleFetchAllWAlletTransactions
                    }
                    walletId={walletId}
                    activeFilters={activeFilters}
                    isLoading={isLoading || allWalletsTransactionsLoading}
                    displayWalletColumn={displayWalletColumn}
                    displayColumns={visibleColumnNames}
                />
                <CreateExportDrawer
                    handleOpenCreateDrawer={handleOpenCreateDrawer}
                    isOpen={createDrawerOpen}
                    preSelectedFilters={filters}
                    preSelectedWalletId={walletId !== null ? walletId : walletsIds}
                />
            </div>
        </div>
    );
};
