import { TransactionContext } from "src/shared/context/transaction";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useCallback, useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { handleUpdateWalletTransactionComment } from "src/modules/walletsTransactions/thunkActions";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useAppDispatch } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { TextArea } from "src/shared/atoms/Inputs/TextArea";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    walletId: string;
};

export function HistoryWalletDrawerComment({ walletId }: Props) {
    const transaction = useContext(TransactionContext);
    const dispatch = useAppDispatch();
    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation();

    const { comment: oldComment, uuid } = transaction;

    const [comment, setComment] = useState<string>(oldComment ?? "");
    const [edit, setEdit] = useState(false);

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });

    const handleSetComment = (newDescription: string) => {
        setComment(newDescription);
    };

    const handleOpenEdit = useCallback(() => {
        setEdit(true);
    }, []);

    const handleCloseEdit = useCallback(() => {
        setEdit(false);
    }, []);

    const handleCancelEdit = useCallback(() => {
        setComment("");
        handleCloseEdit();
    }, [handleCloseEdit]);

    const handleUpdateComment = useCallback(() => {
        if (uuid) {
            setDisabled(true);
            dispatch(handleUpdateWalletTransactionComment({ walletId, transactionUuid: uuid, comment }))
                .then(() => handleCloseEdit())
                .finally(() => setDisabled(false));
        }
    }, [dispatch, handleCloseEdit, comment, walletId, uuid]);

    return (
        <div className='drawer-content-section history-wallet-drawer-comments'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("CommonUse.comment")} />
                {!oldComment && !edit ? (
                    <Button variant='ghost' color='lilas' onClick={handleOpenEdit} label={t("CommonUse.modify")} />
                ) : (
                    edit && <Typography className='fw-bold color-warning-500' message={t("CommonUse.being-modified")} />
                )}
            </div>
            <div className='drawer-content-section-body'>
                {!edit ? (
                    <div className='drawer-comment-content'>
                        <Typography
                            className='fw-bold text-muted color-neutral-500'
                            message={t("CommonUse.description")}
                        />
                        <Typography
                            className='fw-bold text-muted overflow-hidden'
                            message={oldComment}
                            withPlaceholder
                        />
                    </div>
                ) : (
                    <>
                        <TextArea
                            disabled={isUserUnauthorized}
                            value={comment}
                            onChange={handleSetComment}
                            rows={4}
                            placeholder={t<string>("Tasks.add-comment")}
                        />
                    </>
                )}
            </div>
            {edit && (
                <div className='drawer-content-section-footer'>
                    <div className='button-section'>
                        <Button
                            onClick={handleCancelEdit}
                            className='flex-grow-1'
                            label={t("CommonUse.cancel")}
                            variant='tertiary'
                        />
                        <Button
                            disabled={comment.trim().length === 0 || isUserUnauthorized || disabled}
                            onClick={handleUpdateComment}
                            className='flex-grow-1'
                            label={t("CommonUse.validate")}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
