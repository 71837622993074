import { AdminWalletModel } from "src/shared/models/Wallet";
import { formatPhone } from "src/shared/utils/formatPhone";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type WalletInformationDrawerSignatairesProps = {
    wallet: AdminWalletModel;
};

export const WalletInformationDrawerSignataires = ({ wallet }: WalletInformationDrawerSignatairesProps) => {
    const { t } = useTranslation();

    const isOrderedSignatures = wallet.isOrderedSignatures;
    const superSignataires = wallet.users.filter((user) => user.isSuperSignature);

    const signataires = wallet.users.filter((user) => !user.isSuperSignature);
    const groupedSignataires: Record<number, typeof signataires> = {};
    signataires.forEach((user) => {
        const order = user.order ?? 0;
        if (!groupedSignataires[order]) {
            groupedSignataires[order] = [];
        }
        groupedSignataires[order].push(user);
    });

    const sortedOrders = Object.keys(groupedSignataires)
        .map(Number)
        .sort((a, b) => a - b);

    return (
        <div className='wallet-information-drawer-signataires-container'>
            <div className='header'>
                <Typography message={t("Wallets.signer-or-and-super-singer")} />
            </div>
            <div className='content'>
                {signataires.length > 0 && (
                    <div className='signataires-bloc'>
                        <div className='title-container'>
                            <Typography message={t("Tasks.signatories")} className='fw-bold' />
                        </div>
                        {isOrderedSignatures ? (
                            <div className='signataires-list'>
                                {sortedOrders.map((order) => (
                                    <div key={order} className='signataires-group'>
                                        <div className='title-item'>
                                            <Avatar
                                                icon='arrow-right-circle'
                                                backgroundColor='lilas-50'
                                                color='lilas-900'
                                            />
                                            <Typography
                                                message={t(`Wallets.singataire-order`, { order })}
                                                className='fw-bold'
                                            />
                                        </div>
                                        {groupedSignataires[order].map((user) => (
                                            <div className='item' key={user.id}>
                                                <div className='signataire-item'>
                                                    <Avatar
                                                        avatar={user.avatar ?? undefined}
                                                        firstname={user.firstname}
                                                        lastname={user.lastname}
                                                    />
                                                    <div className='signataire-item-name'>
                                                        <Typography
                                                            message={`${user.firstname} ${user.lastname}`}
                                                            className='fw-bold'
                                                        />
                                                        <Typography message={formatPhone(user.phone)} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='signataires-list'>
                                <div className='title-item'>
                                    <Avatar icon='arrow-right-circle' backgroundColor='lilas-50' color='lilas-900' />
                                    <Typography message={t("Roles.user")} className='fw-bold' />
                                </div>
                                {signataires.map((user) => (
                                    <div className='item' key={user.id}>
                                        <div className='signataire-item'>
                                            <Avatar
                                                avatar={user.avatar ?? undefined}
                                                firstname={user.firstname}
                                                lastname={user.lastname}
                                            />

                                            <div className='signataire-item-name'>
                                                <Typography
                                                    message={user.firstname + " " + user.lastname}
                                                    className='fw-bold'
                                                />
                                                <Typography message={formatPhone(user.phone)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className='title-item'>
                            <Avatar icon='check-circle' backgroundColor='success-50' color='success-500' />
                            <Typography message={t("Tasks.send-payment")} className='fw-bold' />
                        </div>
                    </div>
                )}
                {superSignataires.length > 0 && (
                    <>
                        {signataires.length > 0 && <div className='divider' />}
                        <div className='signataires-bloc'>
                            <div className='title-container'>
                                <Typography message={t("Wallets.super-signer")} className='fw-bold' />
                                <Typography message={t("Wallets.super-signer-under-msg")} />
                            </div>
                            <div className='signataires-list'>
                                <div className='title-item'>
                                    <Avatar icon='arrow-right-circle' backgroundColor='lilas-50' color='lilas-900' />
                                    <Typography message={t("Wallets.super-sign")} className='fw-bold' />
                                </div>
                                {superSignataires.map((user) => (
                                    <div className='item' key={user.id}>
                                        <div className='signataire-item'>
                                            <Avatar
                                                avatar={user.avatar ?? undefined}
                                                firstname={user.firstname}
                                                lastname={user.lastname}
                                            />

                                            <div className='signataire-item-name'>
                                                <Typography
                                                    message={user.firstname + " " + user.lastname}
                                                    className='fw-bold'
                                                />
                                                <Typography message={formatPhone(user.phone)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className='title-item'>
                                    <Avatar icon='check-circle' backgroundColor='success-50' color='success-500' />
                                    <Typography message={t("Tasks.send-payment")} className='fw-bold' />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
