import { ConnectedRouter } from "./ConnectedRouter";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { getAuthUser, getUseBaseRouter } from "src/modules/auth/selectors";
import { useIntercom } from "src/shared/hooks/useIntercom";
import { useAppSelector } from "src/store";

import { EmailCheckPage } from "./pages/EmailCheck";
import { LoginPage } from "./pages/Login";

export const BaseRouter = () => {
    const useBaseRouter = useAppSelector((state) => getUseBaseRouter(state));

    const location = useLocation();

    const authUser = useAppSelector((state) => getAuthUser(state));

    useIntercom();

    const isLoggedIn =
        !!authUser && authUser.ownPassword && !useBaseRouter && !location.pathname.includes("/email-validation/");

    return (
        <>
            {isLoggedIn ? (
                <ConnectedRouter />
            ) : (
                <Routes>
                    <Route path='/' element={<LoginPage />} />
                    <Route path='/email-validation/:token' element={<EmailCheckPage />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            )}
        </>
    );
};
