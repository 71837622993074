import { BeneficiaryModel } from "src/shared/models/Beneficiary";
import { validateField } from "src/shared/utils/validateField";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminBeneficiaries } from "src/modules/admin/adminBeneficiaries/selectors";
import { clearBeneficiaryIntegration } from "src/modules/admin/adminBeneficiaries/slice";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type InformationsKeys = "email" | "firstname" | "lastname" | "label";
type InformationsForm = Pick<BeneficiaryModel, InformationsKeys>;
export type InformationsParams = InformationsForm;

type Props = {
    editInformations: BeneficiaryModel | null;
    setInformations: (value: InformationsParams) => void;
    validateForms: () => void;
    isBulk?: boolean;
};

export const AdminBeneficiaryDrawerSectionInfo = ({
    editInformations,
    setInformations,
    validateForms,
    isBulk = false,
}: Props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { integrateBeneficiaryLoading, integratedBeneficiaryResponse } = useAppSelector(getAdminBeneficiaries);

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isEditing, setIsEditing] = useState(isBulk);
    const [fields, setFields] = useState<InformationsParams>({
        email: editInformations?.email ?? "",
        firstname: editInformations?.firstname ?? "",
        lastname: editInformations?.lastname ?? "",
        label: editInformations?.label ?? "",
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isEmailTouched, setIsEmailTouched] = useState(false);

    const fieldValidationTypes: Record<keyof InformationsParams, "email" | "common" | "text"> = {
        email: "email",
        firstname: "common",
        lastname: "common",
        label: "text",
    };

    const debouncedEmail = useDebounce(fields.email, 700);

    const handleChangeInput = (name: keyof InformationsParams) => (value: string) => {
        setFields((prev) => ({ ...prev, [name]: value }));

        if (name !== "email") {
            const validationType = fieldValidationTypes[name];
            const { isValid, errorKey } = validateField(validationType, value);
            setErrors((prev) => {
                if (!isValid && errorKey) {
                    return { ...prev, [name]: t<string>(errorKey) };
                } else {
                    const { [name]: _, ...rest } = prev;
                    return rest;
                }
            });
        }
    };

    useEffect(() => {
        if (debouncedEmail && !validateField("email", debouncedEmail).isValid) {
            setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
        } else {
            setErrors((prev) => {
                const { email, ...rest } = prev;
                return rest;
            });
        }
    }, [debouncedEmail, t]);

    const handleBlur = (name: keyof InformationsParams) => () => {
        if (name === "email") {
            setIsEmailTouched(true);

            if (!validateField("email", fields.email).isValid) {
                setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
            } else {
                setErrors((prev) => {
                    const { email, ...rest } = prev;
                    return rest;
                });
            }
        }
    };

    useEffect(() => {
        const isFormValid = Object.values(fields).every((value) => value !== "") && Object.keys(errors).length === 0;
        setIsFormValidated(isFormValid);
        setInformations(fields);
    }, [fields, errors, setInformations]);

    useEffect(() => {
        if (integratedBeneficiaryResponse) {
            setIsEditing(false);
            dispatch(clearBeneficiaryIntegration());
        }
    }, [integratedBeneficiaryResponse, dispatch]);

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <div className='drawer-content-section mb-3'>
            <div className='drawer-content-section-header'>
                <Typography
                    className='fw-bold'
                    message={t("CreateBeneficiaryInformationForm.informations-sur-le-beneficiaire")}
                />
                <div onClick={handleToggleEdit}>
                    {!isEditing ? (
                        <Typography
                            className='fw-bold cursor-pointer color-lilas-900'
                            message={t("CommonUse.modify")}
                        />
                    ) : (
                        <Typography
                            className={`fw-bold cursor-pointer ${!isFormValidated ? "color-warning" : "color-success"}`}
                            message={!isFormValidated ? t("CommonUse.to-complete") : t("CommonUse.done")}
                        />
                    )}
                </div>
            </div>
            {isEditing ? (
                <div className='beneficiary-informations-content drawer-content-section-body'>
                    <div className='form-part'>
                        <div className='inline-inputs'>
                            <Input
                                autoFocus
                                label={t("CreateBeneficiaryInformationForm.prenom-du-beneficiaire").toString()}
                                name='firstname'
                                value={fields.firstname}
                                placeholder={t("CreateBeneficiaryInformationForm.prenom")}
                                onChange={handleChangeInput("firstname")}
                                variant={errors.firstname ? "error" : "primary"}
                                underMessage={errors.firstname}
                            />
                            <Input
                                label={t("CreateBeneficiaryInformationForm.nom-du-beneficiaire").toString()}
                                name='lastname'
                                value={fields.lastname}
                                placeholder={t("CreateBeneficiaryInformationForm.nom")}
                                onChange={handleChangeInput("lastname")}
                                variant={errors.lastname ? "error" : "primary"}
                                underMessage={errors.lastname}
                            />
                        </div>
                        <Input
                            label={t("CreateBeneficiaryInformationForm.adresse-mail-facultatif").toString()}
                            name='email'
                            value={fields.email}
                            placeholder={t("CommonUse.email-exemple")}
                            onChange={handleChangeInput("email")}
                            onBlur={handleBlur("email")}
                            variant={errors.email || (isEmailTouched && !fields.email) ? "error" : "primary"}
                            underMessage={errors.email}
                        />
                        <Input
                            label={t("Beneficiaries.add-note").toString()}
                            name='label'
                            value={fields.label}
                            placeholder={t("Beneficiaries.add-note-placeholder")}
                            onChange={handleChangeInput("label")}
                            variant={errors.label ? "error" : "primary"}
                            underMessage={errors.label}
                        />
                    </div>
                </div>
            ) : (
                <div className='operation-details-content drawer-content-section-body'>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.firstnames")} />
                        <Typography message={editInformations?.firstname} />
                    </div>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.name")} />
                        <Typography message={editInformations?.lastname} />
                    </div>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.email")} />
                        <Typography message={editInformations?.email || "-"} />
                    </div>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.label")} />
                        <Typography message={editInformations?.label} />
                    </div>
                    {editInformations?.externalAccounts && (
                        <div className='content-item'>
                            <Typography message={t("CommonUse.nb-accounts")} />
                            <Typography message={editInformations?.externalAccounts.length.toString()} />
                        </div>
                    )}
                </div>
            )}

            {isEditing && (
                <div className='button-part'>
                    <Button
                        className='flex-grow-1'
                        variant='tertiary'
                        label={t("CommonUse.cancel")}
                        onClick={handleToggleEdit}
                    />
                    <Button
                        className='flex-grow-1'
                        variant='primary'
                        loading={integrateBeneficiaryLoading}
                        label={!integrateBeneficiaryLoading ? t("CommonUse.validate") : t("CommonUse.send")}
                        disabled={!isFormValidated || integrateBeneficiaryLoading}
                        onClick={validateForms}
                    />
                </div>
            )}
        </div>
    );
};
