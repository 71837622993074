import { TasksItemEditFieldAmount } from "./Fields/Amount";
import { TasksItemEditFieldComment } from "./Fields/Comment";
import { TasksItemEditFieldCreateAmount } from "./Fields/Create/Amount";
import { TasksItemCreateFieldExternalName } from "./Fields/Create/ExternalName";
import { TasksItemEditFieldExternalName } from "./Fields/ExternalName";
import { CreateTaskApiPayload, createTaskItems } from "src/services/taskItems/operations";
import { TaskItemModelToCreate } from "src/services/taskItems/types";
import { TransactionServiceSlug } from "src/services/transactions/types";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";

import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { useRouterTaskItems } from "src/modules/tasksItems/hooks";
import {
    getTaskItemsFileUploading,
    getTaskItemsFileUploadingFailed,
    getTaskItemsUpdating,
} from "src/modules/tasksItems/selectors";
import { removeFileUploadingTaskItem } from "src/modules/tasksItems/slice";
import { deleteTaskItem, fetchTaskItems } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

type Props = {
    taskId?: number;
    handleOpenDrawer: (taskItem: TaskItemTmpModel) => void;
    handleCancelCreateTaskItem: () => void;
    createdItem?: TaskItemModelToCreate;
    loadingCreate: boolean;
};

export function useComputeRowsForEditTable({
    taskId,
    createdItem,
    loadingCreate,
    handleCancelCreateTaskItem,
    handleOpenDrawer,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [retryTasks, setRetryTasks] = useState<{ [id: string]: boolean }>({});
    const [deleteTaskItems, setDeleteTaskItems] = useState<{ [id: string]: boolean }>({});

    const taskItems = useRouterTaskItems();
    const taskItemsFileUploadingFailed = useAppSelector((state) => getTaskItemsFileUploadingFailed(state, { taskId }));
    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const taskItemsUpdating = useAppSelector((state) => getTaskItemsUpdating(state, { taskId }));

    const fileUploading = Object.keys(taskItemsFileUploading ?? {}).length > 0;

    const handleDeleteTaskItem = (taskItem: TaskItemTmpModel) => () => {
        if (taskId) {
            setDeleteTaskItems({ ...deleteTaskItems, [taskItem.id]: true });
            dispatch(deleteTaskItem({ taskId, taskItemIds: [String(taskItem.id)] }))
                .then(() => dispatch(fetchTaskToEdit({ taskId })))
                .finally(() => setDeleteTaskItems({ ...deleteTaskItems, [taskItem.id]: false }));
        }
    };

    const handleRetry = (taskItemId: string, taskItem: CreateTaskApiPayload) => async () => {
        const { externalReference, externalFullname, serviceSlug, typeSlug, amount } = taskItem;
        if (!retryTasks[taskItemId] && taskId && taskItemId && externalReference && amount) {
            setRetryTasks({ ...retryTasks, [taskItemId]: true });
            await createTaskItems({
                taskId,
                tasksItems: [
                    {
                        externalReference,
                        externalFullname: removeExtraSpaces(externalFullname ?? ""),
                        serviceSlug,
                        amount: String(amount),
                        typeSlug,
                    },
                ],
            }).then(() => {
                dispatch(removeFileUploadingTaskItem({ taskId, taskItemId }));
                dispatch(fetchTaskToEdit({ taskId }));
                dispatch(
                    fetchTaskItems({
                        taskId,
                    })
                );
            });
            setRetryTasks({ ...retryTasks, [taskItemId]: false });
        }
    };

    const taskItemsComputed = useMemo(() => {
        return Object.values(taskItems).map<SelectableTableRow[]>((taskItem) => {
            const loading = deleteTaskItems[taskItem.id] ?? taskItemsUpdating?.[taskItem.id] ?? false;
            const { errorCode } = taskItem;
            const errorMessage = errorCode
                ? typeof errorCode === "string"
                    ? t(`Api-errors.${errorCode.toLowerCase().replaceAll("_", "-")}`)
                    : t("Api-errors.some-error-occured")
                : null;

            return [
                {
                    id: taskItem.id,
                    content: (
                        <TransactionServiceImage
                            classname='d-flex align-items-center justify-content-center'
                            serviceSlug={taskItem.serviceSlug}
                        />
                    ),
                    status: !!errorMessage && "error",
                    showIcon: false,
                    type: "button",
                },
                {
                    content: <Typography message={taskItem.externalReference} />,
                    type: "status",
                    status: !!errorMessage && "error",
                    showIcon: false,
                    onClick: handleOpenDrawer(taskItem),
                },
                {
                    content: <TasksItemEditFieldExternalName taskItem={taskItem} loading={loading} taskId={taskId} />,
                    type: "status",
                    status: !!errorMessage && "error",
                    showIcon: false,
                },
                {
                    content: <TasksItemEditFieldAmount loading={loading} taskItem={taskItem} taskId={taskId} />,
                    type: "status",
                    status: !!errorMessage && "error",
                    showIcon: false,
                },
                {
                    content: (
                        <Typography
                            className='fw-bold task-fees-cell'
                            message={formatMoneyToString({ amount: taskItem.fees })}
                        />
                    ),
                    type: "status",
                    status: !!errorMessage && "error",
                    showIcon: false,
                },
                {
                    content: <TasksItemEditFieldComment taskItem={taskItem} taskId={taskId} loading={loading} />,
                    type: "status",
                    status: !!errorMessage && "error",
                    showIcon: false,
                },
                {
                    content: loading ? <Spinner /> : <Icon name='thrash' className='cursor-pointer' />,
                    type: "button",
                    status: !!errorMessage && "error",
                    toolTip: errorMessage && !loading ? { title: errorMessage } : undefined,
                    showIcon: false,
                    onClick: handleDeleteTaskItem(taskItem),
                },
            ] as SelectableTableRow[];
        });
    }, [taskItems, taskId, deleteTaskItems, taskItemsUpdating]);

    const createdItemComputed = useMemo(() => {
        return createdItem
            ? [
                  [
                      {
                          content: createdItem.externalReference ? (
                              <TransactionServiceImage
                                  classname='d-flex align-items-center justify-content-center'
                                  serviceSlug={createdItem.serviceSlug}
                              />
                          ) : (
                              <></>
                          ),
                          type: "button",
                          id: "createItem",
                      },
                      {
                          content: <Typography message={createdItem.externalReference} />,
                          type: "status",
                      },
                      {
                          content: <TasksItemCreateFieldExternalName taskId={taskId} />,
                          type: "status",
                      },
                      {
                          content: (
                              <TasksItemEditFieldCreateAmount
                                  handleCancelCreateTaksItem={handleCancelCreateTaskItem}
                                  taskId={taskId}
                              />
                          ),
                          type: "status",
                      },
                      {
                          content: <Typography className='fw-bold' message={"-"} />,
                          type: "status",
                      },
                      {
                          content: <Typography className='fw-bold' message={createdItem.comment} />,
                          type: "status",
                      },
                      {
                          content: loadingCreate ? <Spinner /> : <Icon name='thrash' className='cursor-pointer' />,
                          onClick: loadingCreate ? undefined : handleCancelCreateTaskItem,
                          type: "button",
                      },
                  ],
              ]
            : [];
    }, [createdItem, loadingCreate]);

    const fileUploadFailedComputed = useMemo(() => {
        return taskItemsFileUploadingFailed
            ? Object.entries(taskItemsFileUploadingFailed).map(([id, taskItem]) => {
                  return [
                      {
                          content: (
                              <TransactionServiceImage
                                  classname='d-flex align-items-center justify-content-center'
                                  serviceSlug={(taskItem.serviceSlug as TransactionServiceSlug) ?? null}
                              />
                          ),
                          type: "button",
                      },
                      {
                          content: <Typography message={taskItem.externalReference} />,
                          type: "status",
                      },
                      {
                          content: <Typography message={taskItem.externalFullname} />,
                          type: "status",
                      },
                      {
                          content: (
                              <Typography
                                  className='fw-bold'
                                  message={formatMoneyToString({ amount: taskItem.amount })}
                              />
                          ),
                          type: "status",
                      },
                      {
                          content: <Typography className='fw-bold' message={"-"} />,
                          type: "status",
                      },
                      {
                          content: <Typography className='fw-bold' message={taskItem.comment} />,
                          type: "status",
                      },
                      {
                          content: retryTasks[id] ? <Spinner /> : <Icon name='retry' className='cursor-pointer' />,
                          onClick: retryTasks[id] ? undefined : handleRetry(id, taskItem),
                          type: "button",
                      },
                  ];
              })
            : [];
    }, [taskItemsFileUploadingFailed, retryTasks]);

    const fileUploadingComputed = useMemo(() => {
        return fileUploading
            ? Object.values(taskItemsFileUploading ?? {}).map<SelectableTableRow[]>((taskItem) => {
                  return [
                      {
                          content: (
                              <TransactionServiceImage
                                  classname='d-flex align-items-center justify-content-center'
                                  serviceSlug={(taskItem.serviceSlug as TransactionServiceSlug) ?? null}
                              />
                          ),
                          type: "button",
                      },
                      {
                          content: <Typography message={taskItem.externalReference} />,
                          type: "status",
                      },
                      {
                          content: <Typography message={taskItem.externalFullname} />,
                          type: "status",
                      },
                      {
                          content: (
                              <Typography
                                  className='fw-bold'
                                  message={formatMoneyToString({ amount: taskItem.amount })}
                              />
                          ),
                          type: "status",
                      },
                      {
                          content: <Typography className='fw-bold' message={"-"} />,
                          type: "status",
                      },
                      {
                          content: <Typography className='fw-bold' message={taskItem.comment} />,
                          type: "status",
                      },
                      {
                          content: <Spinner />,
                          type: "button",
                      },
                  ];
              })
            : [];
    }, [fileUploading, taskItemsFileUploading]);

    const content = useMemo(() => {
        return [...taskItemsComputed, ...createdItemComputed, ...fileUploadFailedComputed, ...fileUploadingComputed];
    }, [taskItemsComputed, createdItemComputed, fileUploadFailedComputed, fileUploadingComputed]);

    return {
        rows: content as SelectableTableRow[][],
        taskItems,
    };
}

export function useComputeRowsForSignatureTasksTable() {
    const taskItems = useRouterTaskItems();

    const taskItemsComputed = useMemo(() => {
        return Object.values(taskItems).map<SelectableTableRow[]>((taskItem) => {
            return [
                {
                    content: (
                        <TransactionServiceImage
                            classname='d-flex align-items-center justify-content-center'
                            serviceSlug={taskItem.serviceSlug}
                        />
                    ),
                    type: "text",
                },
                {
                    content: <Typography message={taskItem.externalReference} />,
                    type: "text",
                },
                {
                    content: <Typography message={taskItem.externalFullname} />,
                    type: "text",
                },
                {
                    content: (
                        <Typography className='fw-bold' message={formatMoneyToString({ amount: taskItem.amount })} />
                    ),
                    type: "text",
                },
                {
                    content: (
                        <Typography className='fw-bold' message={formatMoneyToString({ amount: taskItem.fees })} />
                    ),
                    type: "text",
                },
                {
                    content: <Typography className='fw-bold' message={taskItem.comment} />,
                    type: "text",
                },
            ] as SelectableTableRow[];
        });
    }, [taskItems]);

    return {
        rows: taskItemsComputed as SelectableTableRow[][],
        taskItems,
    };
}
