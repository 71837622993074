import { LayoutBreadcrumbStandard } from "../Standard";

import { useTranslation } from "react-i18next";

export function AdminBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
            ]}
        />
    );
}

export function AdminWalletsBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("NavLinks.wallet-admin"),
                },
            ]}
        />
    );
}

export function AdminWalletCreateBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("NavLinks.wallet-admin"),
                    link: "/admin/wallets",
                },
                {
                    label: t("CommonUse.add", { entity: t("Wallets.a-wallet") }),
                },
            ]}
        />
    );
}

export function AdminWalletEditBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("NavLinks.wallet-admin"),
                    link: "/admin/wallets",
                },
                {
                    label: t("Wallets.edit-an-account"),
                },
            ]}
        />
    );
}

export function AdminWalletDetailsBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("NavLinks.wallet-admin"),
                    link: "/admin/wallets",
                },
                {
                    label: t("Wallets.consult-account"),
                },
            ]}
        />
    );
}

export function AdminUsersBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("Users.my-team"),
                },
            ]}
        />
    );
}

export function AdminBeneficiariesBreadcrumb() {
    const { t } = useTranslation();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("Beneficiaries.my-beneficiaries"),
                },
            ]}
        />
    );
}

export function AdminDocumentsBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("CommonUse.my-repo"),
                },
            ]}
        />
    );
}

export function AdminExpensePolicyBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("Expense-policy.title"),
                },
            ]}
        />
    );
}

export function AdminAccountActivityBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("AdminActivity.title"),
                },
            ]}
        />
    );
}

export function AdminMyCompanyBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("CommonUse.administration"),
                    link: "/admin",
                    icon: "home",
                },
                {
                    label: t("CommonUse.my-company"),
                },
            ]}
        />
    );
}
