import { CreateTemplateBodyDrawer } from "../Drawers/Templates/Body";
import { ExportTemplateTables } from "./ExportTemplateTables";
import { useLocation } from "react-router-dom";
import { fetchExportTemplate } from "src/services/export/operations";
import { ExportTemplate, ExportTemplateFormType } from "src/shared/models/Export";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { ToggleSwitch, ToggleSwitchOption } from "src/shared/atoms/Toggler/Toggler";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "../styles.scss";

const firstOptionDisplay: ToggleSwitchOption = { icon: "document", label: "Export.export-models" };
const secondOptionDisplay: ToggleSwitchOption = { icon: "clock", label: "Export.scheduled-export" };

type LocationState = {
    openCreateExportTemplateDrawer?: boolean;
};
export type exportDisplayOptions = { display?: "models" | "programmedExports" };

export const ExportTemplatesPanel = () => {
    const { t } = useTranslation();

    const state = useLocation().state as exportDisplayOptions;

    const isFeatureExportTemplateLimited = useSubscriptionRestrictions(
        SubscriptionRestrictions.FEATURE_EXPORT_TEMPLATE
    );

    const [display, setDisplay] = useState(firstOptionDisplay);
    const [regularExportTemplateList, setRegularExportTemplateList] = useState<ExportTemplate[]>([]);
    const [scheduledExportTemplateList, setScheduledExportTemplateList] = useState<ExportTemplate[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<ExportTemplate | undefined>(undefined);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [selectedPreSelectedFormType, setSelectedPreSelectedFormType] = useState<
        ExportTemplateFormType | undefined
    >();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [query, setQuery] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [lastEvaluatedKeysStack, setLastEvaluatedKeysStack] = useState<any[]>([null]);

    const locationState = useLocation().state as LocationState;

    useEffect(() => {
        if (locationState?.openCreateExportTemplateDrawer) {
            handleOpenCreateDrawer();
        }
    }, [locationState]);

    const handleFetchLists = async () => {
        setIsLoading(true);
        const lastEvaluatedKey = lastEvaluatedKeysStack[currentPage - 1];
        const payload = {
            limit: 20,
            types:
                display === firstOptionDisplay
                    ? ["regular"]
                    : (["bank_statement", "recurrent"] as ExportTemplateFormType[]),
            ...(query && { name: query }),
            ...(lastEvaluatedKey && { lastEvaluatedKey }),
        };

        const response = await fetchExportTemplate(payload);
        const newTemplates = response.templates || [];
        if (display === firstOptionDisplay) {
            setRegularExportTemplateList(newTemplates);
        } else {
            setScheduledExportTemplateList(newTemplates);
        }

        setHasMoreItems(!!response.lastEvaluatedKey);

        if (response.lastEvaluatedKey && currentPage === lastEvaluatedKeysStack.length) {
            setLastEvaluatedKeysStack([...lastEvaluatedKeysStack, response.lastEvaluatedKey]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleFetchLists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, currentPage, display]);

    useEffect(() => {
        if (state?.display) {
            setDisplay(state.display === "models" ? firstOptionDisplay : secondOptionDisplay);
        }
    }, [state]);

    const handlePageChange = (isNext: boolean) => {
        if (isNext && hasMoreItems) {
            setCurrentPage(currentPage + 1);
        } else if (!isNext && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleOpenCreateDrawer = (
        template?: ExportTemplate,
        dublicate?: boolean,
        preSelectedFormType?: ExportTemplateFormType
    ) => {
        setCreateDrawerOpen(!createDrawerOpen);
        setSelectedTemplate(template);
        setIsDuplicate(dublicate ?? false);
        setSelectedPreSelectedFormType(preSelectedFormType);
    };

    const handleChangeDisplay = (newDisplay: ToggleSwitchOption) => {
        setDisplay(newDisplay);
    };

    const handleRefreshList = (display: ExportTemplateFormType) => {
        handleFetchLists();
        handleChangeDisplay(display === "regular" ? firstOptionDisplay : secondOptionDisplay);
    };

    const handleSetQueryFilter = useCallback((newQuery: string) => {
        setQuery(newQuery);
    }, []);

    return (
        <div className='export-templates-panel-container'>
            <PageHeader>
                <div className='export-header-container'>
                    <div className='left-content'>
                        <ToggleSwitch
                            selected={display}
                            onChange={handleChangeDisplay}
                            firstOption={firstOptionDisplay}
                            secondOption={secondOptionDisplay}
                        />
                        <Input
                            placeholder={t("CommonUse.search")}
                            inputIcon='magnifying-glass'
                            onChange={handleSetQueryFilter}
                            value={query}
                            className='search-input'
                        />
                    </div>
                    <ToolTip
                        position='bottom'
                        content={
                            <div className='tooltip-limited'>
                                <Typography message={t("Subscription.not-accessible-feature")} className='fw-bold' />
                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                            </div>
                        }
                        activation={isFeatureExportTemplateLimited}
                    >
                        <Button
                            label={t("Export.new-export-template")}
                            icon='plus-circle'
                            disabled={isFeatureExportTemplateLimited}
                            onClick={() => handleOpenCreateDrawer()}
                        />
                    </ToolTip>
                </div>
            </PageHeader>
            <div className='export-template-content'>
                <ExportTemplateTables
                    templateType={display.label === firstOptionDisplay.label ? "regular" : "recurrent"}
                    exportTemplates={
                        display.label === firstOptionDisplay.label
                            ? regularExportTemplateList
                            : scheduledExportTemplateList
                    }
                    isLoading={isLoading}
                    refreshList={handleRefreshList}
                    openExportTemplateDrawer={handleOpenCreateDrawer}
                    canGoBack={currentPage > 1}
                    currentPage={currentPage}
                    hasMoreItems={hasMoreItems}
                    onPageChange={handlePageChange}
                    queryNotFound={query !== "" && scheduledExportTemplateList.length === 0}
                />
            </div>
            <CreateTemplateBodyDrawer
                handleOpenCreateDrawer={handleOpenCreateDrawer}
                isOpen={createDrawerOpen}
                template={selectedTemplate}
                isDuplicate={isDuplicate}
                refreshList={handleRefreshList}
                preSelectedFormType={selectedPreSelectedFormType}
            />
        </div>
    );
};
