import { validateField } from "src/shared/utils/validateField";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminExpensePoliciesCreateTitle } from "src/modules/admin/expensePolicies/selectors";
import { setAdminExpensePolicyCreateName } from "src/modules/admin/expensePolicies/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";

type Props = {
    step?: number;
    handleSetStep?: (newStep: number) => () => void;
};

export function AdminExpensePolicyCreateDrawerGeneral({ step, handleSetStep }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const name = useAppSelector((state) => getAdminExpensePoliciesCreateTitle(state));
    const [error, setError] = useState<string | null>(null);

    const handleChangeLimitPerOperation = (value: string) => {
        dispatch(setAdminExpensePolicyCreateName({ value }));
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && handleSetStep && step === 4 && error !== null) {
                handleSetStep(5)();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    useEffect(() => {
        if (name) {
            if (!validateField("common", name).isValid) {
                setError(t("CommonUse.no-spec-char"));
            } else {
                setError(null);
            }
        }
    }, [name]);

    useEffect(() => {
        console.log("error", error);
    }, [error]);

    return (
        <StepAccordion
            showSuccess={false}
            title={t("Expense-policy.general-informations")}
            subTitle={t<string>("Expense-policy.name-and-description-of-rule")}
            icon='information-circle'
            currentStep={handleSetStep ? step : 5}
            step={4}
            footer={
                handleSetStep && (
                    <Button
                        disabled={!name || error !== null}
                        label={t("CommonUse.validate")}
                        onClick={handleSetStep(5)}
                    />
                )
            }
        >
            <div className='pt-3'>
                <Input
                    autoFocus
                    inputClassname='background-white'
                    label={t<string>("Expense-policy.rule-name")}
                    placeholder={t("Expense-policy.rume-name-exemple")}
                    value={name}
                    onChange={handleChangeLimitPerOperation}
                    variant={error ? "error" : undefined}
                    underMessage={error ? error : undefined}
                />
            </div>
        </StepAccordion>
    );
}
